export const EntityDepGraph = {
AdditionalEmployeeInfo: ['Country', 'Gender', 'Incident', 'State', 'User'],
AdditionalEmployeeInfoHistory: ['AdditionalEmployeeInfo', 'Country', 'Gender', 'Incident', 'State', 'User'],
AdditionalForm300Info: ['Incident', 'User'],
AdditionalForm300InfoHistory: ['AdditionalForm300Info', 'Incident', 'User'],
AdditionalForm301Info: ['HealthcareFacility', 'HealthcareProfessional', 'Incident', 'User'],
AdditionalForm301InfoHistory: ['AdditionalForm301Info', 'HealthcareFacility', 'Incident', 'User'],
AdditionalInfo: ['User'],
AffectedBodyPart: ['BodyPart', 'Incident', 'RecordableDetails', 'User'],
AppNotificationType: ['User'],
AssociatedType: ['User'],
Attachment: ['AssociatedType', 'Company', 'User'],
AttachmentAssociation: ['AssociatedType', 'Attachment', 'User'],
AuditEventStatus: ['User'],
AuditEventType: ['User'],
AuditScheduleTemplate: ['Checklist', 'Company', 'Employee', 'Equipment', 'Groups', 'Location', 'NotificationType', 'ScoringMethod', 'User', 'WorkArea'],
AuditScheduleTemplateJunction: ['AuditScheduleTemplate', 'Schedule', 'User'],
Binder: ['Company', 'User'],
BinderChemicalSafetyDataSheet: ['Chemical', 'GlobalSafetyDataSheet', 'SafetyDataSheet', 'User'],
BinderExpiration: ['Company', 'User'],
BinderStatus: ['User'],
BinderVersion: ['Binder', 'BinderStatus', 'User'],
BinderVersionLocation: ['BinderVersion', 'Location', 'User'],
BinderVersionSafetyDataSheet: ['BinderVersion', 'GlobalSafetyDataSheet', 'SafetyDataSheet', 'User'],
BinderVersionWorkArea: ['BinderVersion', 'User', 'WorkArea'],
BodyPart: ['Company', 'Groups', 'User'],
CampaignPerSubscriptionType: ['SubscriptionType'],
Checklist: ['Company', 'Country', 'Groups', 'State', 'User'],
ChecklistArea: ['Checklist', 'User'],
ChecklistCitation: ['Checklist', 'User'],
ChecklistGoverningBody: ['Checklist', 'User'],
ChecklistIndustry: ['Checklist', 'User'],
ChecklistLocation: ['Checklist', 'Location', 'User'],
ChecklistSection: ['Checklist', 'User'],
ChecklistSectionJjkaQuestion: ['ChecklistSection', 'User'],
ChecklistSectionQuestion: ['ChecklistSection', 'Question', 'User'],
ChecklistSettings: ['Company', 'User'],
ChecklistTopic: ['Checklist', 'User'],
Chemical: ['Company', 'Country', 'GlobalProduct', 'GlobalSafetyDataSheet', 'Groups', 'Manufacturer', 'PhysicalState', 'SafetyDataSheet', 'SafetyDataSheetStatus', 'State', 'User'],
ChemicalIdentifier: ['Chemical', 'Identifier', 'User'],
ChemicalSafetyDataSheet: ['Chemical', 'SafetyDataSheet', 'User'],
ChemicalSafetyDataSheetParent: ['Chemical', 'SafetyDataSheet', 'User'],
ChemicalSettings: ['Company', 'User'],
ClassroomEventReminder: ['Company', 'User'],
Company: ['Subscription', 'User'],
CompanyUsage: ['Chemical', 'ContainerType', 'ContainerUnitOfMeasure', 'Location', 'User', 'WorkArea'],
ContainerType: ['User'],
ContainerUnitOfMeasure: ['User'],
CostCenter: ['Company', 'Groups', 'User'],
Country: ['User'],
County: ['State', 'User'],
CustomGhsHazard: ['NotClassifiedGhsHazard', 'SafetyDataSheet', 'User'],
CustomText: ['NonAuth', 'User'],
DaysAwayOrDaysRestricted: ['Incident', 'RecordableDetails', 'User'],
Employee: ['Company', 'CostCenter', 'Country', 'EmploymentType', 'Gender', 'Groups', 'JobTitle', 'Location', 'MedicalAccommodationStatus', 'Shift', 'State', 'User', 'WorkArea'],
EmployeeJobFunction: ['Employee', 'JobFunction', 'User'],
EmployeeLearnerGroup: ['Company', 'Employee', 'LearnerGroup', 'User'],
EmployeeViewModel: [],
EmploymentDataMonthly: ['Company', 'EmploymentDataYear', 'Location', 'PeakEmployeeCount', 'User'],
EmploymentDataOshaSubmissionRequired: ['IncidentReport', 'NaicsCode', 'PeakEmployeeCount', 'User'],
EmploymentDataYear: ['Company', 'User'],
EmploymentDataYearly: ['Company', 'EmploymentDataYear', 'Location', 'PeakEmployeeCount', 'User'],
EmploymentType: ['User'],
Equipment: ['Company', 'CostCenter', 'Equipment', 'Groups', 'Location', 'User', 'WorkArea'],
Event: ['AuditEventStatus', 'AuditEventType', 'AuditScheduleTemplate', 'Checklist', 'Company', 'Employee', 'Equipment', 'Groups', 'Location', 'ScoringMethod', 'User', 'WorkArea'],
EventReview: ['Company', 'Event', 'User'],
EventReviewQuestion: ['EventReviewSection', 'User'],
EventReviewQuestionAvailableResponse: ['EventReviewQuestion', 'User'],
EventReviewQuestionCorrectResponse: ['EventReviewQuestion', 'User'],
EventReviewSection: ['EventReview', 'User'],
Features: ['User'],
FeaturesPerCompany: ['Company', 'Features', 'User'],
FeaturesPerSubscription: ['Features', 'SubscriptionType', 'User'],
FeaturesPerUserOrGroup: ['Features', 'User', 'UserGroups'],
Gender: ['User'],
GeneralInformation: ['Employee', 'Equipment', 'Incident', 'IncidentStatus', 'IncidentType', 'Location', 'User', 'WhereEventOccurred', 'WorkArea'],
GeneralInformationHistory: ['Employee', 'Equipment', 'GeneralInformation', 'Incident', 'IncidentStatus', 'IncidentType', 'Location', 'User', 'WhereEventOccurred', 'WorkArea'],
GhsHazard: ['GhsHazardInfo', 'SafetyDataSheet', 'User'],
GhsHazardClass: ['GhsHazardType', 'User'],
GhsHazardInfo: ['GhsHazardClass', 'User'],
GhsHazardType: ['User'],
GhsPictogram: ['GhsPictogramInfo', 'SafetyDataSheet', 'User'],
GhsPictogramInfo: ['User'],
GlobalIngredient: ['GlobalPercentBy', 'GlobalProportion', 'GlobalSafetyDataSheet', 'User'],
GlobalManufacturer: ['Country', 'State', 'User'],
GlobalManufacturerSearch: [],
GlobalPercentBy: ['User'],
GlobalPersonalProtectiveEquipment: ['GlobalPersonalProtectiveEquipmentInfo', 'GlobalSafetyDataSheet', 'User'],
GlobalPersonalProtectiveEquipmentInfo: ['User'],
GlobalPictograms: ['GlobalPictogramsInfo', 'GlobalSafetyDataSheet', 'User'],
GlobalPictogramsInfo: ['User'],
GlobalProduct: ['GlobalManufacturer', 'PhysicalState', 'ProductMonitoringStatus', 'User'],
GlobalProductViewModel: [],
GlobalProportion: ['User'],
GlobalSafetyDataSheet: ['GlobalProduct', 'GlobalSdsStatus', 'Language', 'User'],
GlobalSDSSearch: [],
GlobalSdsStatus: ['User'],
GlobalStatements: ['GlobalSafetyDataSheet', 'GlobalStatementType', 'User'],
GlobalStatementType: ['User'],
Grid: ['User'],
GridView: ['Company', 'Grid', 'User'],
GridViewState: ['GridView', 'User'],
Groups: ['Company', 'Groups', 'User'],
GroupsRolePerUserOrGroup: ['Groups', 'Roles', 'User', 'UserGroups'],
Hazard: ['GlobalSafetyDataSheet', 'HazardCategory', 'HazardType', 'User'],
HazardCategory: ['User'],
HazardType: ['User'],
HealthcareFacility: ['Company', 'Country', 'Groups', 'State', 'User'],
HealthcareProfessional: ['Company', 'Country', 'Groups', 'State', 'User'],
HomeNotifications: [],
Identifier: ['Company', 'Groups', 'User'],
Impersonations: ['User'],
ImportExportLog: ['User'],
Incident: ['Company', 'Groups', 'User'],
IncidentIntake: ['Company', 'Location', 'SafetyObservationType', 'User', 'WorkArea'],
IncidentIntakeNotifyUser: ['NonAuth', 'User'],
IncidentReport: ['User'],
IncidentSettings: ['Company', 'User'],
IncidentStatus: ['User'],
IncidentType: ['User'],
IncidentViewModel: [],
Ingredient: ['SafetyDataSheet', 'User'],
InjuryCause: ['Company', 'Groups', 'User'],
InjuryIllnessType: ['Company', 'Groups', 'User'],
InjuryIllnessTypes: ['Incident', 'InjuryIllnessType', 'RecordableDetails', 'User'],
Investigation: ['Incident', 'RiskLevel', 'User'],
InvestigationHistory: ['Incident', 'Investigation', 'RiskLevel', 'User'],
Invitations: ['User'],
JobFunction: ['Company', 'Groups', 'User'],
JobTitle: ['Company', 'Groups', 'User'],
Language: ['User'],
LearnerGroup: ['Company', 'Groups', 'User'],
LegacyLearnerData: ['Company', 'User'],
Location: ['Company', 'Country', 'County', 'Groups', 'NaicsCode', 'State', 'User'],
Manufacturer: ['Country', 'State', 'User'],
MaterialCodes: ['SubscriptionType', 'User'],
MedicalAccommodationStatus: ['User'],
MedicalTest: ['Employee', 'MedicalTestResult', 'User'],
MedicalTestResult: ['User'],
MedicalVaccination: ['Employee', 'MedicalVaccinationStatus', 'User'],
MedicalVaccinationStatus: ['User'],
NaicsCode: ['User'],
NfpaHazard: ['NfpaHazardInfo', 'SafetyDataSheet', 'User'],
NfpaHazardInfo: ['NfpaHazardType', 'User'],
NfpaHazardType: ['User'],
NonAuth: ['Company', 'Groups', 'Location', 'Roles', 'User'],
NotClassifiedGhsHazard: ['GhsHazardType', 'User'],
Note: ['AssociatedType', 'Company', 'User'],
NotificationMethod: [],
NotificationRecipient: ['AppNotificationType', 'Company', 'NotificationMethod', 'User'],
NotificationType: ['User'],
NumberingSequenceLocation: ['Company', 'Location', 'User'],
NumberingSequenceType: ['Company', 'User'],
OktaUserMigration: ['User'],
OshaInjuryClassification: ['User'],
PeakEmployeeCount: ['User'],
Permissions: ['User'],
PermissionsPerRole: ['Permissions', 'Roles', 'User'],
PhysicalState: ['User'],
PiiViewLog: ['Company', 'User'],
PlanAndPolicy: ['Company', 'Country', 'Groups', 'State', 'User'],
PlanAndPolicyArea: ['PlanAndPolicy', 'User'],
PlanAndPolicyCitation: ['PlanAndPolicy', 'User'],
PlanAndPolicyGoverningBody: ['PlanAndPolicy', 'User'],
PlanAndPolicyIndustry: ['PlanAndPolicy', 'User'],
PlanAndPolicyTopic: ['PlanAndPolicy', 'User'],
ProductMonitoringStatus: ['User'],
Question: ['Company', 'Country', 'State', 'User'],
QuestionArea: ['Question', 'User'],
QuestionAvailableResponse: ['Question', 'User'],
QuestionCitation: ['Question', 'User'],
QuestionCorrectResponse: ['Question', 'User'],
QuestionGoverningBody: ['Question', 'User'],
QuestionIndustry: ['Question', 'User'],
QuestionTopic: ['Question', 'User'],
RecordableDetails: ['Incident', 'InjuryCause', 'OshaInjuryClassification', 'User'],
RecordableDetailsHistory: ['Incident', 'InjuryCause', 'OshaInjuryClassification', 'RecordableDetails', 'User'],
RecurringEventsFailures: ['User'],
Report: ['User'],
ReportColumn: ['Report', 'User'],
Request: ['Chemical', 'Company', 'Country', 'GlobalProduct', 'PhysicalState', 'RequestStatus', 'State', 'User'],
RequestAdditionalInfo: ['AdditionalInfo', 'Request', 'User'],
RequestStatus: ['User'],
RequestUnableToSourceReason: ['Request', 'UnableToSourceReason', 'User'],
RequestVmView: [],
Reseller: [],
RiskLevel: ['User'],
Roles: ['User'],
SafetyDataSheet: ['Chemical', 'Language', 'Manufacturer', 'SafetyDataSheetParent', 'User'],
SafetyDataSheetParent: ['Manufacturer', 'SafetyDataSheetStatus', 'User'],
SafetyDataSheetStatus: ['User'],
SafetyObservationType: ['User'],
Schedule: ['Company', 'User'],
ScoringMethod: ['User'],
SecurityQuestion: ['User'],
Shift: ['Company', 'Groups', 'User'],
StagedFiles: ['Company', 'User'],
State: ['Country', 'User'],
Subscription: ['SubscriptionType', 'User'],
SubscriptionDetails: ['Subscription', 'User'],
SubscriptionType: ['User'],
SupportDeskAccessToken: ['Company', 'User'],
Synonym: ['Chemical', 'User'],
Task: ['Company', 'Employee', 'Groups', 'Location', 'TaskPriority', 'TaskStatus', 'TaskType', 'User', 'WorkArea'],
TaskPriority: ['User'],
TaskStatus: ['User'],
TaskType: ['User'],
TermsOfUse: ['User'],
TodAccessToken: ['Company', 'User'],
UnableToSourceReason: ['User'],
User: ['Company', 'Groups', 'SecurityQuestion', 'User'],
UserAcceptedTerms: ['TermsOfUse', 'User'],
UserDataProfile: ['Country', 'State', 'User'],
UserGroups: ['Company', 'Groups', 'User', 'UserGroups'],
UserGroupsMembers: ['User', 'UserGroups'],
UserGroupsRole: ['Groups', 'User', 'UserGroups'],
UserType: ['User'],
WhereEventOccurred: ['Company', 'Groups', 'User'],
WorkArea: ['Company', 'Groups', 'User'],
};
