<div class="modal fade" [id]="id" [attr.alphaId]="alphaId" data-bs-backdrop="static" data-bs-keyboard="false"
  role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="header-content">
          <h1 class="modal-title" [id]="ariaLabelId">{{title}}</h1>
          <p class="modal-description">{{description}}</p>
        </div>
        <button type="button" class="btn-close" (click)="onXClicked()" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <app-qr-code [data]="data"></app-qr-code>
        <i class="fa-solid fa-arrow-down-to-line icon-style"></i>
        <a (click)="downloadQRCode()">Download QR Code - PNG</a>
        <br>
        <i class="fa-solid fa-copy icon-style"></i>
        <a (click)="copyQRCode()">Copy QR Code</a>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="onConfirmClicked()">Close</button>
      </div>
    </div>
  </div>
</div>