// AUTO-GENERATED CODE
//////////////////////////////////
// AUTO-GENERATED CODE DO NOT EDIT
//////////////////////////////////
import { AuditScheduleTemplate } from './auditscheduletemplate';
import { BodyPart } from './bodypart';
import { Checklist } from './checklist';
import { Chemical } from './chemical';
import { Company } from './company';
import { CostCenter } from './costcenter';
import { Employee } from './employee';
import { Equipment } from './equipment';
import { Event } from './event';
import { GroupsRolePerUserOrGroup } from './groupsroleperuserorgroup';
import { HealthcareFacility } from './healthcarefacility';
import { HealthcareProfessional } from './healthcareprofessional';
import { Identifier } from './identifier';
import { Incident } from './incident';
import { InjuryCause } from './injurycause';
import { InjuryIllnessType } from './injuryillnesstype';
import { JobFunction } from './jobfunction';
import { JobTitle } from './jobtitle';
import { LearnerGroup } from './learnergroup';
import { Location } from './location';
import { NonAuth } from './nonauth';
import { PlanAndPolicy } from './planandpolicy';
import { Shift } from './shift';
import { Task } from './task';
import { User } from './user';
import { UserGroups } from './usergroups';
import { UserGroupsRole } from './usergroupsrole';
import { WhereEventOccurred } from './whereeventoccurred';
import { WorkArea } from './workarea';

//////////////////////////////////
// AUTO-GENERATED CODE DO NOT EDIT
//////////////////////////////////
import { IValidatorInfo, ValidatorInfo } from '@models/validations/validatorInfo';
import { IModelRecord, IModelAudit, IModelBase } from '@models/interfaces/entity-interfaces';

//////////////////////////////////
// AUTO-GENERATED CODE DO NOT EDIT
//////////////////////////////////
export class Groups  {

//////////////////////////////////
// AUTO-GENERATED CODE DO NOT EDIT
//////////////////////////////////
        companyId: string;
        createdByUserId: string;
        createdDate: Date;
        id: string;
        impersonationCreatedByUserId?: string;
        impersonationModifiedByUserId?: string;
        isDeleted: boolean;
        isShared: boolean;
        modifiedByUserId?: string;
        modifiedDate?: Date;
        name: string;
        parentGroupId?: string;
        groupId: string;
        permissions: number;

//////////////////////////////////
// AUTO-GENERATED CODE DO NOT EDIT
//////////////////////////////////
    constructor(json?: Partial<Groups>) {
        this.companyId = json && json.companyId;
        this.createdByUserId = json && json.createdByUserId;
        this.createdDate = (!json || json.createdDate === undefined) ? null : (json.createdDate === null ? new Date() : json.createdDate);
        this.id = json && json.id;
        this.impersonationCreatedByUserId = json && json.impersonationCreatedByUserId;
        this.impersonationModifiedByUserId = json && json.impersonationModifiedByUserId;
        this.isDeleted = json && json.isDeleted;
        this.isShared = json && json.isShared;
        this.modifiedByUserId = json && json.modifiedByUserId;
        this.modifiedDate = (!json || json.modifiedDate === undefined) ? null : json.modifiedDate;
        this.name = json && json.name;
        this.parentGroupId = json && json.parentGroupId;
        this.groupId = json && json.groupId;
        this.permissions = json && json.permissions || 0;

    }

//////////////////////////////////
// AUTO-GENERATED CODE DO NOT EDIT
//////////////////////////////////
    _validateInfo(fieldName: string): IValidatorInfo {
        switch (fieldName) {
        case 'companyId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'createdByUserId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'createdDate': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'Date',
            };
        }
        case 'id': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'impersonationCreatedByUserId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'impersonationModifiedByUserId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'isDeleted': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'boolean',
            };
        }
        case 'isShared': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'boolean',
            };
        }
        case 'modifiedByUserId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'modifiedDate': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'Date',
            };
        }
        case 'name': {
            return {
                required: true,
                minLength: undefined, maxLength: 50,
                type: 'string',
            };
        }
        case 'parentGroupId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
            default: return { required: false, minLength: undefined, maxLength: undefined, type: undefined };
        }
    }

//////////////////////////////////
// AUTO-GENERATED CODE DO NOT EDIT
//////////////////////////////////
    _validate(skip: string[] = []): [number, number, string[]?] {
        let count = 0;
        const errorFields = [];
        if (!skip.includes('companyId') && ValidatorInfo.validateString(this.companyId, this._validateInfo('companyId'))) {
            count++;
            errorFields.push('companyId');
        }
        if (!skip.includes('createdByUserId') && ValidatorInfo.validateString(this.createdByUserId, this._validateInfo('createdByUserId'))) {
            count++;
            errorFields.push('createdByUserId');
        }
        if (!skip.includes('createdDate') && ValidatorInfo.validateDate(this.createdDate, this._validateInfo('createdDate'))) {
            count++;
            errorFields.push('createdDate');
        }
        if (!skip.includes('id') && ValidatorInfo.validateString(this.id, this._validateInfo('id'))) {
            count++;
            errorFields.push('id');
        }
        if (!skip.includes('impersonationCreatedByUserId') && ValidatorInfo.validateString(this.impersonationCreatedByUserId, this._validateInfo('impersonationCreatedByUserId'))) {
            count++;
            errorFields.push('impersonationCreatedByUserId');
        }
        if (!skip.includes('impersonationModifiedByUserId') && ValidatorInfo.validateString(this.impersonationModifiedByUserId, this._validateInfo('impersonationModifiedByUserId'))) {
            count++;
            errorFields.push('impersonationModifiedByUserId');
        }
        if (!skip.includes('isDeleted') && ValidatorInfo.validateString(this.isDeleted, this._validateInfo('isDeleted'))) {
            count++;
            errorFields.push('isDeleted');
        }
        if (!skip.includes('isShared') && ValidatorInfo.validateString(this.isShared, this._validateInfo('isShared'))) {
            count++;
            errorFields.push('isShared');
        }
        if (!skip.includes('modifiedByUserId') && ValidatorInfo.validateString(this.modifiedByUserId, this._validateInfo('modifiedByUserId'))) {
            count++;
            errorFields.push('modifiedByUserId');
        }
        if (!skip.includes('modifiedDate') && ValidatorInfo.validateDate(this.modifiedDate, this._validateInfo('modifiedDate'))) {
            count++;
            errorFields.push('modifiedDate');
        }
        if (!skip.includes('name') && ValidatorInfo.validateString(this.name, this._validateInfo('name'))) {
            count++;
            errorFields.push('name');
        }
        if (!skip.includes('parentGroupId') && ValidatorInfo.validateString(this.parentGroupId, this._validateInfo('parentGroupId'))) {
            count++;
            errorFields.push('parentGroupId');
        }
        return [count, 8, errorFields];
    }
}
