import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { AgChartsAngularModule } from 'ag-charts-angular';
import { AgGridModule } from 'ag-grid-angular';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { QRCodeModule } from 'angularx-qrcode';
import { AccordionItemComponent } from '@components/accordion/accordion-item.component';
import { ActivateInactivateComponent } from '@components/activate-inactivate/activate-inactivate.component';
import { AddressComponent } from '@components/address/address.component';
import { AdvancedFilterComponent } from '@components/advanced-filter/advanced-filter.component';
import { AGGridActionsCustomColumnComponent } from '@components/ag-grid-actions-custom-column/ag-grid-actions-custom-column.component';
import { AGGridActionsWithSubmenuColumnComponent } from '@components/ag-grid-actions-custom-column/ag-grid-actions-with-submenu-column.component';
import { AGGridCustomColumnActionComponent } from '@components/ag-grid-child-components/ag-grid-custom-column-action.component';
import { AGGridColumnComponent } from '@components/ag-grid-column/ag-grid-column.component';
import { AgGridCustomAutocompleteFilterComponent } from '@components/ag-grid-custom-autocomplete-filter/ag-grid-custom-autocomplete-filter.component';
import { AgGridCustomAutocompleteFloatingFilterComponent } from '@components/ag-grid-custom-autocomplete-floating-filter/ag-grid-custom-autocomplete-floating-filter.component';
import { AgGridCustomListFilterComponent } from '@components/ag-grid-custom-list-filter/ag-grid-custom-list-filter.component';
import { AgGridCustomListFloatingFilter } from '@components/ag-grid-custom-list-floating-filter/ag-grid-custom-list-floating-filter.component';
import { AgGridCustomTimeFilterComponent } from '@components/ag-grid-custom-time-filter/ag-grid-custom-time-filter.component';
import { AGGridCustomToolbarComponent } from '@components/ag-grid-custom-toolbar/ag-grid-custom-toolbar.component';
import { AGGridCustomToolbarV2Component } from '@components/ag-grid-custom-toolbarV2/ag-grid-custom-toolbarV2.component';
import { AGGridCustomToolPanelComponent } from '@components/ag-grid-custom-toolpanel/ag-grid-custom-toolpanel.component';
import { AGGridDownloadCustomColumnComponent } from '@components/ag-grid-download-custom-column/ag-grid-download-custom-column.component';
import { AgGridMaskedValueCellComponent } from '@components/ag-grid-masked-value-cell/ag-grid-masked-value-cell.component';
import { AGGridQuickActionsComponent } from '@components/ag-grid-quick-actions/ag-grid-quick-actions.component';
import { AgGridRecurringAuditCellRendererComponent } from '@components/ag-grid-recurring-audit-cell-renderer/ag-grid-recurring-audit-cell-renderer.component';
import { AgGridSdsStatusTooltipComponent } from '@components/ag-grid-sds-status-tooltip/ag-grid-sds-status-tooltip.component';
import { AGGridSettingsToolPanelComponent } from '@components/ag-grid-settings-toolpanel/ag-grid-settings-toolpanel.component';
import { AGGridSimpleComponent } from '@components/ag-grid-simple/ag-grid-simple.component';
import { AGGridComponent } from '@components/ag-grid/ag-grid.component';
import { AGGridV2Component } from '@components/ag-gridv2/ag-gridv2.component';
import { AlertDismissComponent } from '@components/alert-dismiss/alert-dismiss.component';
import { AlertComponent } from '@components/alert/alert.component';
import { AlphaIndexComponent } from '@components/alpha-index/alpha-index.component';
import { AlphaNavigationComponent } from '@components/alpha-navigation/alpha-navigation.component';
import { AttachmentsEditModalComponent } from '@components/attachments-edit-modal/attachments-edit-modal.component';
import { AttachmentsComponent } from '@components/attachments/attachments.component';
import { AuditInfoComponent } from '@components/audit-info/audit-info.component';
import { EnterEmailComponent } from '@components/auth/forgot-password/enter-email/enter-email.component';
import { ForgotPasswordOktaOtherApplicationsComponent } from '@components/auth/forgot-password/forgot-password-okta-other-applications/forgot-password-okta-other-applications.component';
import { NewPasswordComponent } from '@components/auth/forgot-password/new-password/new-password.component';
import { MigrateLoginFormComponent } from '@components/auth/migrate-login-form/migrate-login-form.component';
import { PasswordRulesComponent } from '@components/auth/password-rules/password-rules.component';
import { VerificationCodeComponent } from '@components/auth/verification-code/verification-code.component';
import { BackButtonComponent } from '@components/back-button/back-button.component';
import { BreadcrumbComponent } from '@components/breadcrumb/breadcrumb.component';
import { CardLinkComponent } from '@components/cards/card-link/card-link.component';
import { CardsBarComponent } from '@components/cards/cards-bar/cards-bar.component';
import {
    JJKAuditDashboardChartAuditInspectionAndConformanceChartComponent,
} from '@components/charts/audit-dashboard-chart-audit-inspection-and-conformance-chart/jjk-audit-dashboard-chart-audit-inspection-and-conformance-chart.component';
import { JJKBaseChartComponent } from '@components/charts/base-chart/base-chart.component';
import { ChemicalSDSAgingChartComponent } from '@components/charts/chemical-sds-aging-chart/chemical-sds-aging-chart.component';
import { ComboChartComponent, ComboSeriesVerticalComponent } from '@components/charts/combo-chart';
import { JJKCompletedTasksChartComponent } from '@components/charts/completed-tasks-chart/jjk-completed-tasks-chart.component';
import { JJKDataRecordableIncidentAndDartRateChartComponent } from '@components/charts/data-recordable-incident-and-dart-rate-chart/jjk-data-recordable-incident-and-dart-rate-chart.component';
import { JJKIncidentsByTypeChartComponent } from '@components/charts/incidents-by-type-chart/jjk-incidents-by-type-chart.component';
import { JJKTaskBreakdownChartComponent } from '@components/charts/task-breakdown-chart/jjk-task-breakdown-chart.component';
import { JJKTrainingChartComponent } from '@components/charts/training-chart/jjk-training-chart.component';
import { CheckboxListComponent } from '@components/checkbox-list/checkbox-list.component';
import { CitationsSelectComponent } from '@components/citations-select/citations-select.component';
import { CobrandingLogoComponent } from '@components/cobranding-logo/cobranding-logo.component';
import { DateColumnComponent } from '@components/column-date/date-column.component';
import { FileDetailsColumnComponent } from '@components/column-file-details/file-details-column.component';
import { TextColumnComponent } from '@components/column-text/text-column.component';
import { CSPChemicalLabelConfiguratorComponent } from '@components/csp-chemicals-label-configurator/csp-chemicals-label-configurator.component';
import { DataGridAdvancedFilterItemComponent } from '@components/datagrid-advanced-filter-item/datagrid-advanced-filter-item.component';
import { DataGridAdvancedFilterComponent } from '@components/datagrid-advanced-filter/datagrid-advanced-filter.component';
import { DataGridAdvancedSearchComponent } from '@components/datagrid-advanced-search/datagrid-advanced-search.component';
import { DataGridColumnCheckboxComponent } from '@components/datagrid-column-checkbox/datagrid-column-checkbox.component';
import { DataGridColumnCustomActionComponent } from '@components/datagrid-column-custom-action/datagrid-column-custom-action.component';
import { DataGridColumnCustomContentComponent } from '@components/datagrid-column-custom-content/datagrid-column-custom-content.component';
import { DatagridColumnCustomDropdownComponent } from '@components/datagrid-column-custom-dropdown/datagrid-column-custom-dropdown.component';
import { DataGridColumnCustomLinkComponent } from '@components/datagrid-column-custom-link/datagrid-column-custom-link.component';
import { DataGridColumnCustomComponent } from '@components/datagrid-column-custom/datagrid-column-custom.component';
import { DataGridColumnFileDownloadComponent } from '@components/datagrid-column-file-download/datagrid-column-file-download.component';
import { DataGridColumnFileComponent } from '@components/datagrid-column-file/datagrid-column-file.component';
import { DataGridColumnInputComponent } from '@components/datagrid-column-input/datagrid-column-input.component';
import { DataGridColumnLabelComponent } from '@components/datagrid-column-label/datagrid-column-label.component';
import { DataGridColumnTextComponent } from '@components/datagrid-column-text/datagrid-column-text.component';
import { DataGridColumnToggleComponent } from '@components/datagrid-column-toggle/datagrid-column-toggle.component';
import { DataGridColumnComponent } from '@components/datagrid-column/datagrid-column.component';
import { DataGridCustomColumnContentComponent } from '@components/datagrid-custom-column-content/datagrid-custom-column-content.component';
import { DataGridFilterComponent } from '@components/datagrid-filter/datagrid-filter.component';
import { DataGridPipeManager } from '@components/datagrid/datagrid-pipemanager.service';
import { DataGridComponent } from '@components/datagrid/datagrid.component';
import { DatePickerComponent } from '@components/datepicker/date-picker.component';
import { DateRangePickerComponent } from '@components/daterangepicker/date-range-picker.component';
import { DeleteComponent } from '@components/delete/delete.component';
import { DragAndDropActionItemComponent } from '@components/drag-and-drop-action-item/drag-and-drop-action-item.component';
import { DragAndDropGridIndexComponent } from '@components/drag-and-drop-grid-index/drag-and-drop-grid-index.component';
import { DragAndDropGridSectionRowComponent } from '@components/drag-and-drop-grid-section-row/drag-and-drop-grid-section-row.component';
import { DragAndDropGridStandardRowComponent } from '@components/drag-and-drop-grid-standard-row/drag-and-drop-grid-standard-row.component';
import { DragAndDropGridComponent } from '@components/drag-and-drop-grid/drag-and-drop-grid.component';
import { DynamicFormControlDatePickerComponent } from '@components/dynamic-form-control-date-picker/dynamic-form-control-date-picker.component';
import { DynamicFormControlFileUploadComponent } from '@components/dynamic-form-control-file-upload/dynamic-form-control-file-upload.component';
import { DynamicFormControlMultiSelectComponent } from '@components/dynamic-form-control-multi-select/dynamic-form-control-multi-select.component';
import { DynamicFormControlRichEditComponent } from '@components/dynamic-form-control-richedit/dynamic-form-control-richedit.component';
import { DynamicFormControlSelectComponent } from '@components/dynamic-form-control-select/dynamic-form-control-select.component';
import { DynamicFormControlSliderComponent } from '@components/dynamic-form-control-slider/dynamic-form-control-slider.component';
import { DynamicFormControlTextComponent } from '@components/dynamic-form-control-text/dynamic-form-control-text.component';
import { DynamicFormControlTreeSelectComponent } from '@components/dynamic-form-control-tree-select/dynamic-form-control-tree-select.component';
import { DynamicFormControlComponent } from '@components/dynamic-form-control/dynamic-form-control.component';
import { DynamicFormLabelAppliesToComponent } from '@components/dynamic-form-label-applies-to/dynamic-form-label-applies-to.component';
import { DynamicFormSubFormComponent } from '@components/dynamic-form-sub-form/dynamic-form-sub-form.component';
import { DynamicFormComponent } from '@components/dynamic-form/dynamic-form.component';
import { DynamicModalWizardComponent } from '@components/dynamic-modal-wizard/dynamic-modal-wizard.component';
import { EmptyStateComponent } from '@components/empty-state/empty-state.component';
import { EnrollmentCountCardComponent } from '@components/enrollment-count-card/enrollment-count-card.component';
import { ExpandAllComponent } from '@components/expand-all/expand-all.component';
import { FileIconComponent } from '@components/file-icon/file-icon.component';
import { FileUploadComponent } from '@components/file-upload/file-upload.component';
import { FileViewOrDownloadComponent } from '@components/file-view-or-download/file-view-or-download.component';
import { FilterComponent } from '@components/filter/filter.component';
import { FroalaEditorComponent } from '@components/froala-editor/froala-editor.component';
import { GeographySelectComponent } from '@components/geography-select/geography-select.component';
import { GhsHazardListComponent } from '@components/ghs-hazard-list/ghs-hazard-list.component';
import { GhsHazardComponent } from '@components/ghs-hazard/ghs-hazard.component';
import { GhsPictogramComponent } from '@components/ghspictogram/ghspictogram.component';
import { GlobalPictogramComponent } from '@components/globalPictograms/globalPictogram.component';
import { GridPageHeaderComponent } from '@components/grid-page/header/header.component';
import { GridPageSubactionsComponent } from '@components/grid-page/subactions/subactions.component';
import { ScreenService } from '@components/hacksaw/screen.service';
import { SmsHideableColumnDataComponent } from '@components/hacksaw/sms-hideable-column-data/sms-hideable-column-data.component';
import { SmsMinimapComponent } from '@components/hacksaw/sms-minimap/sms-minimap.component';
import { SmsMinimapService } from '@components/hacksaw/sms-minimap/sms-minimap.service';
import { SmsSortService } from '@components/hacksaw/sms-sortable-column/sms-sort.service';
import { SmsSortableColumnComponent } from '@components/hacksaw/sms-sortable-column/sms-sortable-column.component';
import { SmsSortableTableDirective } from '@components/hacksaw/sms-sortable-table/sms-sortable-table.directive';
import { HorizontalNavigationComponent } from '@components/horizontal-navigation/horizontal-navigation.component';
import { SelectWithSubformComponent } from '@components/jjk-select-with-subform/jjk-select-with-subform.component';
import { LabelComponent } from '@components/label/label.component';
import { LoaderComponent } from '@components/loader/loader.component';
import { LocationGroupSelectComponent } from '@components/location-group-select/location-group-select.component';
import { MedicalAccommodationModalComponent } from '@components/medical-accommodation-modal/medical-accommodation-modal.component';
import { MedicalTestModalOldComponent } from '@components/medical-test-modal/medical-test-modal-old.component';
import { MedicalTestModalComponent } from '@components/medical-test-modal/medical-test-modal.component';
import { MedicalVaccinationModalOldComponent } from '@components/medical-vaccination-modal/medical-vaccination-modal-old.component';
import { MedicalVaccinationModalComponent } from '@components/medical-vaccination-modal/medical-vaccination-modal.component';
import { MiniGridComponent } from '@components/mini-grid/mini-grid.component';
import { ModalWindowComponent } from '@components/modal-window/modal-window.component';
import { NfpaHazardListComponent } from '@components/nfpa-hazard-list/nfpa-hazard-list.component';
import { NfpaHazardComponent } from '@components/nfpa-hazard/nfpa-hazard.component';
import { NotesComponent } from '@components/notes/notes.component';
import { NotificationCardComponent } from '@components/notifications/notification-card/notification-card.component';
import { NotificationsBarComponent } from '@components/notifications/notifications-bar/notifications-bar.component';
import { PageHeaderComponent } from '@components/page-header/page-header.component';
import { PasswordConfirmComponent } from '@components/password-confirm/password-confirm.component';
import { PimcoreZoneComponent } from '@components/pimcore/pimcore-zone.component';
import { PopoverComponent } from '@components/popover/popover.component';
import { ProgressBarComponent } from '@components/progress-bar/progress-bar.component';
import { RemindersNewComponent } from '@components/reminders-new/reminders-new.component';
import { RemindersComponent } from '@components/reminders/reminders.component';
import { SafetyManagementProcessComponent } from '@components/safety-management-process/safety-management-process.component';
import { SaveButtonComponent } from '@components/save-button/save-button.component';
import { SearchTipsComponent } from '@components/search-tips/search-tips.component';
import { SearchComponent } from '@components/search/search.component';
import { SelectComponent } from '@components/select/select.component';
import { TabItemComponent } from '@components/tab-item/tab-item.component';
import { TabListComponent } from '@components/tab-list/tab-list.component';
import { TableSearchComponent } from '@components/table-search/table-search.component';
import { TabsComponent } from '@components/tabs/tabs.component';
import { TimeInputComponent } from '@components/time-input/time-input.component';
import { ToggleComponent } from '@components/toggle/toggle.component';
import { TreeSelectComponent } from '@components/tree-select/tree-select.component';
import { TreeComponent } from '@components/tree/tree.component';
import { AuthorizeDirective } from '@directives/auth.directive';
import { AutofocusDirective } from '@directives/autofocus.directive';
import { CustomInputRestrictionDirective } from '@directives/custom-input-restriction.directive';
import { DisableAutoCompleteInputDirective } from '@directives/disable-auto-complete-input.directive';
import { DisableAutoCompleteNgSelectDirective } from '@directives/disable-auto-complete-ngSelect.directive';
import { FeatureToggleDirective } from '@directives/feature-toggle.directive';
import { NumberOnlyDirective } from '@directives/number-only.directive';
import { PasswordDirective } from '@directives/password.directive';
import { PercentageDirective } from '@directives/percentage.directive';
import { PopoverDirective } from '@directives/popover.directive';
import { SafeHtmlDirective } from '@directives/safe-html.directive';
import { TwoDigitDecimalNumberDirective } from '@directives/twoDigitDecimalNumber.directive';
import { NgSelectModule } from '@ng-select/ng-select';
import { ChemicalsPageHeaderComponent } from '@pages/chemicals/chemicals-page-header/chemicals-page-header.component';
import { AddEditHazardFormComponent } from '@pages/layout/layout-slide-out-modal.component/slide-out-modal-forms/add-edit-hazard-form.component';
import { AddEditIngredientFormComponent } from '@pages/layout/layout-slide-out-modal.component/slide-out-modal-forms/add-edit-ingredient-form.component';
import { AddEditStatementsFormComponent } from '@pages/layout/layout-slide-out-modal.component/slide-out-modal-forms/add-edit-statement-hazard-form.component';
import { SlideOutModalComponent } from '@pages/layout/layout-slide-out-modal.component/slide-out-modal.component';
import { AgGridStateService } from '@services/aggridState.service';
import { AlertService } from '@services/alert.service';
import { AttachmentService } from '@services/attachment.service';
import { NavigationService } from '@services/navigation.service';
import { RouterExtService } from '@services/router-ext.service';
import { ScriptService } from '@services/script.service';
import { SetTitleService } from '@services/set-title.service';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ActiveInactivePipe } from '@utilities/pipes/active-inactive.pipe';
import { BuildRouterLinkPipe } from '@utilities/pipes/build-router-link.pipe';
import { DateFormatPipe } from '@utilities/pipes/date-format.pipe';
import { DateRangeFormatPipe } from '@utilities/pipes/date-range-format.pipe';
import { DueDatePipe } from '@utilities/pipes/due-date.pipe';
import { FullNameFormatPipe } from '@utilities/pipes/full-name-format.pipe';
import { GridGetFieldPipe } from '@utilities/pipes/grid-get-field.pipe';
import { GridGetRowValue } from '@utilities/pipes/grid-get-row-value.pipe';
import { HighPriorityPipe } from '@utilities/pipes/high-priority.pipe';
import { InsertCharPipe } from '@utilities/pipes/insert-char-pipe';
import { LoadRoleDataPipe } from '@utilities/pipes/load-role-data.pipe';
import { MonthFormatPipe } from '@utilities/pipes/month-format.pipe';
import { NewBadgePipe } from '@utilities/pipes/new-badge.pipe';
import { NotApplicablePipe } from '@utilities/pipes/not-applicable.pipe';
import { NotPopulatedPipe } from '@utilities/pipes/not-populated.pipe';
import { RegulatedPipe } from '@utilities/pipes/regulated-badge.pipe';
import { TimeFormatPipe } from '@utilities/pipes/time-format.pipe';
import { TruncateLinkPipe } from '@utilities/pipes/truncate-link.pipe';
import { TruncatePipe } from '@utilities/pipes/truncate.pipe';
import { TrustedContentPipe } from '@utilities/pipes/trusted-content.pipe';
import { TrustedUrlPipe } from '@utilities/pipes/trusted-url.pipe';
import { UnescapePipe } from '@utilities/pipes/unescape.pipe';
import { YesNoSliderPipe } from '@utilities/pipes/yes-no-slider.pipe';
import { EmailAddressValidator } from '@utilities/validators/email-address.validator';
import { IsNumericValidator } from '@utilities/validators/is-numeric.validator';
import { PhoneNumberValidator } from '@utilities/validators/phone-number.validator';
import { ModalWindowQRComponent } from './modal-window-qr/modal-window-qr.component';
import { QRCodeComponent } from './qr-code/qr-code.component';

const components = [
    AccordionItemComponent,
    ActiveInactivePipe,
    ActivateInactivateComponent,
    AddressComponent,
    AdvancedFilterComponent,
    AlertComponent,
    AlertDismissComponent,
    AlphaIndexComponent,
    AlphaNavigationComponent,
    AttachmentsComponent,
    AttachmentsEditModalComponent,
    AuditInfoComponent,
    AuthorizeDirective,
    AutofocusDirective,
    PasswordDirective,
    BackButtonComponent,
    BreadcrumbComponent,
    BuildRouterLinkPipe,
    CardLinkComponent,
    CardsBarComponent,
    CheckboxListComponent,
    CitationsSelectComponent,
    CobrandingLogoComponent,
    ComboChartComponent,
    ComboSeriesVerticalComponent,
    CSPChemicalLabelConfiguratorComponent,
    DataGridColumnComponent,
    DataGridColumnCustomActionComponent,
    DataGridColumnCustomComponent,
    DataGridCustomColumnContentComponent,
    DataGridColumnCustomContentComponent,
    DataGridColumnCustomLinkComponent,
    DataGridColumnFileComponent,
    DataGridColumnFileDownloadComponent,
    DataGridColumnInputComponent,
    DataGridColumnLabelComponent,
    DataGridColumnToggleComponent,
    DataGridColumnCheckboxComponent,
    DataGridColumnTextComponent,
    DataGridComponent,
    DataGridAdvancedFilterComponent,
    DataGridAdvancedFilterItemComponent,
    DataGridAdvancedSearchComponent,
    DatagridColumnCustomDropdownComponent,
    DateColumnComponent,
    DataGridFilterComponent,
    DateFormatPipe,
    DatePickerComponent,
    DateRangeFormatPipe,
    DateRangePickerComponent,
    DeleteComponent,
    DragAndDropActionItemComponent,
    DragAndDropGridComponent,
    DragAndDropGridIndexComponent,
    DragAndDropGridSectionRowComponent,
    DragAndDropGridStandardRowComponent,
    DueDatePipe,
    DynamicFormComponent,
    DynamicFormControlComponent,
    DynamicFormControlDatePickerComponent,
    DynamicFormControlFileUploadComponent,
    DynamicFormControlMultiSelectComponent,
    DynamicFormControlRichEditComponent,
    DynamicFormControlSelectComponent,
    DynamicFormControlTreeSelectComponent,
    DynamicFormControlSliderComponent,
    DynamicFormControlTextComponent,
    DynamicFormLabelAppliesToComponent,
    DynamicFormSubFormComponent,
    DynamicModalWizardComponent,
    EmailAddressValidator,
    EmptyStateComponent,
    EnrollmentCountCardComponent,
    EnterEmailComponent,
    VerificationCodeComponent,
    ExpandAllComponent,
    FeatureToggleDirective,
    FileDetailsColumnComponent,
    FileIconComponent,
    FileUploadComponent,
    FileViewOrDownloadComponent,
    FilterComponent,
    ForgotPasswordOktaOtherApplicationsComponent,
    FroalaEditorComponent,
    FullNameFormatPipe,
    GeographySelectComponent,
    GhsHazardComponent,
    GhsHazardListComponent,
    GhsPictogramComponent,
    GlobalPictogramComponent,
    GridGetFieldPipe,
    GridGetRowValue,
    GridPageHeaderComponent,
    GridPageSubactionsComponent,
    HighPriorityPipe,
    HorizontalNavigationComponent,
    InsertCharPipe,
    IsNumericValidator,
    JJKAuditDashboardChartAuditInspectionAndConformanceChartComponent,
    JJKBaseChartComponent,
    JJKCompletedTasksChartComponent,
    JJKDataRecordableIncidentAndDartRateChartComponent,
    JJKIncidentsByTypeChartComponent,
    JJKTaskBreakdownChartComponent,
    JJKTrainingChartComponent,
    ChemicalSDSAgingChartComponent,
    ChemicalsPageHeaderComponent,
    LabelComponent,
    LoaderComponent,
    LoadRoleDataPipe,
    LocationGroupSelectComponent,
    MedicalAccommodationModalComponent,
    MedicalTestModalComponent,
    MedicalTestModalOldComponent,
    MedicalVaccinationModalComponent,
    MedicalVaccinationModalOldComponent,
    MiniGridComponent,
    ModalWindowComponent,
    ModalWindowQRComponent,
    MonthFormatPipe,
    NewBadgePipe,
    NewPasswordComponent,
    NfpaHazardComponent,
    NfpaHazardListComponent,
    NotApplicablePipe,
    NotPopulatedPipe,
    NotesComponent,
    NotificationsBarComponent,
    NotificationCardComponent,
    NumberOnlyDirective,
    CustomInputRestrictionDirective,
    PageHeaderComponent,
    PasswordConfirmComponent,
    PasswordRulesComponent,
    PhoneNumberValidator,
    PercentageDirective,
    PimcoreZoneComponent,
    PopoverComponent,
    PopoverDirective,
    ProgressBarComponent,
    QRCodeComponent,
    RegulatedPipe,
    RemindersComponent,
    RemindersNewComponent,
    SafeHtmlDirective,
    SafetyManagementProcessComponent,
    SaveButtonComponent,
    SearchComponent,
    SearchTipsComponent,
    SelectComponent,
    SelectWithSubformComponent,
    SmsHideableColumnDataComponent,
    SmsMinimapComponent,
    SmsSortableColumnComponent,
    SmsSortableTableDirective,
    TabItemComponent,
    TabListComponent,
    TableSearchComponent,
    TabsComponent,
    TextColumnComponent,
    TimeFormatPipe,
    TimeInputComponent,
    ToggleComponent,
    TreeComponent,
    TreeSelectComponent,
    TruncateLinkPipe,
    TruncatePipe,
    TrustedContentPipe,
    TrustedUrlPipe,
    UnescapePipe,
    YesNoSliderPipe,
    AGGridComponent,
    AGGridSimpleComponent,
    AGGridV2Component,
    AGGridCustomColumnActionComponent,
    AGGridActionsCustomColumnComponent,
    AgGridMaskedValueCellComponent,
    AGGridActionsWithSubmenuColumnComponent,
    AGGridDownloadCustomColumnComponent,
    AgGridRecurringAuditCellRendererComponent,
    AgGridCustomAutocompleteFilterComponent,
    AgGridCustomAutocompleteFloatingFilterComponent,
    AGGridColumnComponent,
    AGGridCustomToolbarComponent,
    AGGridCustomToolbarV2Component,
    AGGridCustomToolPanelComponent,
    AGGridSettingsToolPanelComponent,
    AgGridCustomListFilterComponent,
    AgGridCustomTimeFilterComponent,
    DisableAutoCompleteInputDirective,
    DisableAutoCompleteNgSelectDirective,
    AgGridSdsStatusTooltipComponent,
    AgGridCustomListFloatingFilter,
    AGGridQuickActionsComponent,
    SlideOutModalComponent,
    AddEditHazardFormComponent,
    AddEditIngredientFormComponent,
    AddEditStatementsFormComponent,
    MigrateLoginFormComponent,
    TwoDigitDecimalNumberDirective,
];

@NgModule({
    imports: [
        FormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
        CommonModule,
        DragDropModule,
        FormsModule,
        FroalaEditorModule.forRoot(),
        FroalaViewModule.forRoot(),
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatButtonModule,
        MatMenuModule,
        MatTabsModule,
        NgSelectModule,
        NgxChartsModule,
        ReactiveFormsModule,
        RouterModule,
        AgGridModule,
        AgChartsAngularModule,
        QRCodeModule,
    ],
    declarations: components,
    exports: [components, AgChartsAngularModule],
    providers: [
        AlertService,
        AttachmentService,
        ScreenService,
        SetTitleService,
        SmsMinimapService,
        SmsSortService,
        DataGridPipeManager,
        ScriptService,
        NavigationService,
        AgGridStateService,
        RouterExtService,
    ],
})
export class SharedModule { }
