import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { LoggerService } from '@services/core/logger-service.class';
import { hideModal, newRandomId, showModal } from '@utilities/helpers';
import { IModalWindowQr } from './modal-windows-qr.model';

@Component({
  selector: 'modal-window-qr',
  templateUrl: './modal-window-qr.component.html',
  styleUrls: ['./modal-window-qr.component.scss'],
})
export class ModalWindowQRComponent implements OnInit, OnDestroy, IModalWindowQr {
  @Input() id = '';
  @Input() title = '';
  @Input() description: string;
  @Input() data: any;
  @Output() onConfirm: EventEmitter<any> = new EventEmitter<any>();

  // Secure id in case a component name is repeated in the screen
  alphaId = newRandomId(4);

  get ariaLabelId(): string {
    return `${this.id}Label`;
  }

  ngOnInit() { }

  ngOnDestroy() {
    $('.modal-backdrop').remove();
    $('body').removeClass('modal-open');
  }

  onCancelClicked() {
    setTimeout(() => {
      this.close();
    }, 50);
  }

  onXClicked() {
    this.close();
  }

  async onConfirmClicked() {
    this.onConfirm.emit(this.data);
    this.close();
  }

  close() {
    hideModal($(`div.modal[alphaId='${this.alphaId}']`));
  }

  show() {
    showModal($(`div.modal[alphaId='${this.alphaId}']`));
  }
  downloadQRCode(): void {
    const qrCodeElement = document.querySelector('qrcode canvas') as HTMLCanvasElement;
    const link = document.createElement('a');
    link.href = qrCodeElement.toDataURL('image/png');
    link.download = 'qr-code.png';
    link.click();
  }

  async copyQRCode(): Promise<void> {
    const qrCodeElement = document.querySelector('qrcode canvas') as HTMLCanvasElement;
    if (qrCodeElement) {
      qrCodeElement.toBlob(async (blob) => {
        try {
          await navigator.clipboard.write([
            new ClipboardItem({
              'image/png': blob
            })
          ]);
        } catch (error) {
          LoggerService.trace('trace', error);
        }
      });
    }
  }
}
