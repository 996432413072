// AUTO-GENERATED CODE
//////////////////////////////////
// AUTO-GENERATED CODE DO NOT EDIT
//////////////////////////////////
import { AdditionalEmployeeInfo } from './additionalemployeeinfo';
import { AdditionalEmployeeInfoHistory } from './additionalemployeeinfohistory';
import { AdditionalForm300Info } from './additionalform300info';
import { AdditionalForm300InfoHistory } from './additionalform300infohistory';
import { AdditionalForm301Info } from './additionalform301info';
import { AdditionalForm301InfoHistory } from './additionalform301infohistory';
import { AdditionalInfo } from './additionalinfo';
import { AffectedBodyPart } from './affectedbodypart';
import { AppNotificationType } from './appnotificationtype';
import { AssociatedType } from './associatedtype';
import { Attachment } from './attachment';
import { AttachmentAssociation } from './attachmentassociation';
import { AuditEventStatus } from './auditeventstatus';
import { AuditEventType } from './auditeventtype';
import { AuditScheduleTemplate } from './auditscheduletemplate';
import { AuditScheduleTemplateJunction } from './auditscheduletemplatejunction';
import { Binder } from './binder';
import { BinderChemicalSafetyDataSheet } from './binderchemicalsafetydatasheet';
import { BinderExpiration } from './binderexpiration';
import { BinderStatus } from './binderstatus';
import { BinderVersion } from './binderversion';
import { BinderVersionLocation } from './binderversionlocation';
import { BinderVersionSafetyDataSheet } from './binderversionsafetydatasheet';
import { BinderVersionWorkArea } from './binderversionworkarea';
import { BodyPart } from './bodypart';
import { Checklist } from './checklist';
import { ChecklistArea } from './checklistarea';
import { ChecklistCitation } from './checklistcitation';
import { ChecklistGoverningBody } from './checklistgoverningbody';
import { ChecklistIndustry } from './checklistindustry';
import { ChecklistLocation } from './checklistlocation';
import { ChecklistSection } from './checklistsection';
import { ChecklistSectionJjkaQuestion } from './checklistsectionjjkaquestion';
import { ChecklistSectionQuestion } from './checklistsectionquestion';
import { ChecklistSettings } from './checklistsettings';
import { ChecklistTopic } from './checklisttopic';
import { Chemical } from './chemical';
import { ChemicalIdentifier } from './chemicalidentifier';
import { ChemicalSafetyDataSheet } from './chemicalsafetydatasheet';
import { ChemicalSafetyDataSheetParent } from './chemicalsafetydatasheetparent';
import { ChemicalSettings } from './chemicalsettings';
import { ClassroomEventReminder } from './classroomeventreminder';
import { Company } from './company';
import { CompanyUsage } from './companyusage';
import { ContainerType } from './containertype';
import { ContainerUnitOfMeasure } from './containerunitofmeasure';
import { CostCenter } from './costcenter';
import { Country } from './country';
import { County } from './county';
import { CustomGhsHazard } from './customghshazard';
import { CustomText } from './customtext';
import { DaysAwayOrDaysRestricted } from './daysawayordaysrestricted';
import { Employee } from './employee';
import { EmployeeJobFunction } from './employeejobfunction';
import { EmployeeLearnerGroup } from './employeelearnergroup';
import { EmploymentDataMonthly } from './employmentdatamonthly';
import { EmploymentDataOshaSubmissionRequired } from './employmentdataoshasubmissionrequired';
import { EmploymentDataYear } from './employmentdatayear';
import { EmploymentDataYearly } from './employmentdatayearly';
import { EmploymentType } from './employmenttype';
import { Equipment } from './equipment';
import { Event } from './event';
import { EventReview } from './eventreview';
import { EventReviewQuestion } from './eventreviewquestion';
import { EventReviewQuestionAvailableResponse } from './eventreviewquestionavailableresponse';
import { EventReviewQuestionCorrectResponse } from './eventreviewquestioncorrectresponse';
import { EventReviewSection } from './eventreviewsection';
import { Features } from './features';
import { FeaturesPerCompany } from './featurespercompany';
import { FeaturesPerSubscription } from './featurespersubscription';
import { FeaturesPerUserOrGroup } from './featuresperuserorgroup';
import { Gender } from './gender';
import { GeneralInformation } from './generalinformation';
import { GeneralInformationHistory } from './generalinformationhistory';
import { GhsHazard } from './ghshazard';
import { GhsHazardClass } from './ghshazardclass';
import { GhsHazardInfo } from './ghshazardinfo';
import { GhsHazardType } from './ghshazardtype';
import { GhsPictogram } from './ghspictogram';
import { GhsPictogramInfo } from './ghspictograminfo';
import { GlobalIngredient } from './globalingredient';
import { GlobalManufacturer } from './globalmanufacturer';
import { GlobalPercentBy } from './globalpercentby';
import { GlobalPersonalProtectiveEquipment } from './globalpersonalprotectiveequipment';
import { GlobalPersonalProtectiveEquipmentInfo } from './globalpersonalprotectiveequipmentinfo';
import { GlobalPictograms } from './globalpictograms';
import { GlobalPictogramsInfo } from './globalpictogramsinfo';
import { GlobalProduct } from './globalproduct';
import { GlobalProportion } from './globalproportion';
import { GlobalSafetyDataSheet } from './globalsafetydatasheet';
import { GlobalSdsStatus } from './globalsdsstatus';
import { GlobalStatements } from './globalstatements';
import { GlobalStatementType } from './globalstatementtype';
import { Grid } from './grid';
import { GridView } from './gridview';
import { GridViewState } from './gridviewstate';
import { Groups } from './groups';
import { GroupsRolePerUserOrGroup } from './groupsroleperuserorgroup';
import { Hazard } from './hazard';
import { HazardCategory } from './hazardcategory';
import { HazardType } from './hazardtype';
import { HealthcareFacility } from './healthcarefacility';
import { HealthcareProfessional } from './healthcareprofessional';
import { Identifier } from './identifier';
import { Impersonations } from './impersonations';
import { ImportExportLog } from './importexportlog';
import { Incident } from './incident';
import { IncidentIntake } from './incidentintake';
import { IncidentIntakeNotifyUser } from './incidentintakenotifyuser';
import { IncidentReport } from './incidentreport';
import { IncidentSettings } from './incidentsettings';
import { IncidentStatus } from './incidentstatus';
import { IncidentType } from './incidenttype';
import { Ingredient } from './ingredient';
import { InjuryCause } from './injurycause';
import { InjuryIllnessType } from './injuryillnesstype';
import { InjuryIllnessTypes } from './injuryillnesstypes';
import { Investigation } from './investigation';
import { InvestigationHistory } from './investigationhistory';
import { Invitations } from './invitations';
import { JobFunction } from './jobfunction';
import { JobTitle } from './jobtitle';
import { Language } from './language';
import { LearnerGroup } from './learnergroup';
import { LegacyLearnerData } from './legacylearnerdata';
import { Location } from './location';
import { Manufacturer } from './manufacturer';
import { MaterialCodes } from './materialcodes';
import { MedicalAccommodationStatus } from './medicalaccommodationstatus';
import { MedicalTest } from './medicaltest';
import { MedicalTestResult } from './medicaltestresult';
import { MedicalVaccination } from './medicalvaccination';
import { MedicalVaccinationStatus } from './medicalvaccinationstatus';
import { NaicsCode } from './naicscode';
import { NfpaHazard } from './nfpahazard';
import { NfpaHazardInfo } from './nfpahazardinfo';
import { NfpaHazardType } from './nfpahazardtype';
import { NonAuth } from './nonauth';
import { NotClassifiedGhsHazard } from './notclassifiedghshazard';
import { Note } from './note';
import { NotificationRecipient } from './notificationrecipient';
import { NotificationType } from './notificationtype';
import { NumberingSequenceLocation } from './numberingsequencelocation';
import { NumberingSequenceType } from './numberingsequencetype';
import { OktaUserMigration } from './oktausermigration';
import { OshaInjuryClassification } from './oshainjuryclassification';
import { PeakEmployeeCount } from './peakemployeecount';
import { Permissions } from './permissions';
import { PermissionsPerRole } from './permissionsperrole';
import { PhysicalState } from './physicalstate';
import { PiiViewLog } from './piiviewlog';
import { PlanAndPolicy } from './planandpolicy';
import { PlanAndPolicyArea } from './planandpolicyarea';
import { PlanAndPolicyCitation } from './planandpolicycitation';
import { PlanAndPolicyGoverningBody } from './planandpolicygoverningbody';
import { PlanAndPolicyIndustry } from './planandpolicyindustry';
import { PlanAndPolicyTopic } from './planandpolicytopic';
import { ProductMonitoringStatus } from './productmonitoringstatus';
import { Question } from './question';
import { QuestionArea } from './questionarea';
import { QuestionAvailableResponse } from './questionavailableresponse';
import { QuestionCitation } from './questioncitation';
import { QuestionCorrectResponse } from './questioncorrectresponse';
import { QuestionGoverningBody } from './questiongoverningbody';
import { QuestionIndustry } from './questionindustry';
import { QuestionTopic } from './questiontopic';
import { RecordableDetails } from './recordabledetails';
import { RecordableDetailsHistory } from './recordabledetailshistory';
import { RecurringEventsFailures } from './recurringeventsfailures';
import { Report } from './report';
import { ReportColumn } from './reportcolumn';
import { Request } from './request';
import { RequestAdditionalInfo } from './requestadditionalinfo';
import { RequestStatus } from './requeststatus';
import { RequestUnableToSourceReason } from './requestunabletosourcereason';
import { RiskLevel } from './risklevel';
import { Roles } from './roles';
import { SafetyDataSheet } from './safetydatasheet';
import { SafetyDataSheetParent } from './safetydatasheetparent';
import { SafetyDataSheetStatus } from './safetydatasheetstatus';
import { SafetyObservationType } from './safetyobservationtype';
import { Schedule } from './schedule';
import { ScoringMethod } from './scoringmethod';
import { SecurityQuestion } from './securityquestion';
import { Shift } from './shift';
import { StagedFiles } from './stagedfiles';
import { State } from './state';
import { Subscription } from './subscription';
import { SubscriptionDetails } from './subscriptiondetails';
import { SubscriptionType } from './subscriptiontype';
import { SupportDeskAccessToken } from './supportdeskaccesstoken';
import { Synonym } from './synonym';
import { Task } from './task';
import { TaskPriority } from './taskpriority';
import { TaskStatus } from './taskstatus';
import { TaskType } from './tasktype';
import { TermsOfUse } from './termsofuse';
import { TodAccessToken } from './todaccesstoken';
import { UnableToSourceReason } from './unabletosourcereason';
import { UserAcceptedTerms } from './useracceptedterms';
import { UserDataProfile } from './userdataprofile';
import { UserGroups } from './usergroups';
import { UserGroupsMembers } from './usergroupsmembers';
import { UserGroupsRole } from './usergroupsrole';
import { UserType } from './usertype';
import { WhereEventOccurred } from './whereeventoccurred';
import { WorkArea } from './workarea';

//////////////////////////////////
// AUTO-GENERATED CODE DO NOT EDIT
//////////////////////////////////
import { IValidatorInfo, ValidatorInfo } from '@models/validations/validatorInfo';
import { IModelRecord, IModelAudit, IModelBase } from '@models/interfaces/entity-interfaces';

//////////////////////////////////
// AUTO-GENERATED CODE DO NOT EDIT
//////////////////////////////////
export class User implements IModelRecord {

//////////////////////////////////
// AUTO-GENERATED CODE DO NOT EDIT
//////////////////////////////////
        azureInternalId: string;
        baseGroupId?: string;
        canSelfRenew?: boolean;
        companyId: string;
        createdByUserId: string;
        createdDate: Date;
        email: string;
        hideInUI?: boolean;
        id: string;
        impersonationCreatedByUserId?: string;
        impersonationModifiedByUserId?: string;
        isActive?: boolean;
        isDeleted: boolean;
        lastReviewed?: Date;
        modifiedByUserId?: string;
        modifiedDate?: Date;
        name: string;
        oktaId: string;
        securityAnswer1: string;
        securityAnswer2: string;
        securityQuestion1Id?: string;
        securityQuestion2Id?: string;
        tODContactInformationId: string;
        tODFacilitatorId: string;
        userCreationType?: number;
        userName: string;
        userType?: number;
        groupId: string;
        permissions: number;

//////////////////////////////////
// AUTO-GENERATED CODE DO NOT EDIT
//////////////////////////////////
    constructor(json?: Partial<User>) {
        this.azureInternalId = json && json.azureInternalId;
        this.baseGroupId = json && json.baseGroupId;
        this.canSelfRenew = json && json.canSelfRenew;
        this.companyId = json && json.companyId;
        this.createdByUserId = json && json.createdByUserId;
        this.createdDate = (!json || json.createdDate === undefined) ? null : (json.createdDate === null ? new Date() : json.createdDate);
        this.email = json && json.email;
        this.hideInUI = json && json.hideInUI;
        this.id = json && json.id;
        this.impersonationCreatedByUserId = json && json.impersonationCreatedByUserId;
        this.impersonationModifiedByUserId = json && json.impersonationModifiedByUserId;
        this.isActive = json && json.isActive;
        this.isDeleted = json && json.isDeleted;
        this.lastReviewed = (!json || json.lastReviewed === undefined) ? null : json.lastReviewed;
        this.modifiedByUserId = json && json.modifiedByUserId;
        this.modifiedDate = (!json || json.modifiedDate === undefined) ? null : json.modifiedDate;
        this.name = json && json.name;
        this.oktaId = json && json.oktaId;
        this.securityAnswer1 = json && json.securityAnswer1;
        this.securityAnswer2 = json && json.securityAnswer2;
        this.securityQuestion1Id = json && json.securityQuestion1Id;
        this.securityQuestion2Id = json && json.securityQuestion2Id;
        this.tODContactInformationId = json && json.tODContactInformationId;
        this.tODFacilitatorId = json && json.tODFacilitatorId;
        this.userCreationType = json && json.userCreationType;
        this.userName = json && json.userName;
        this.userType = json && json.userType;
        this.groupId = json && json.groupId;
        this.permissions = json && json.permissions || 0;

    }

//////////////////////////////////
// AUTO-GENERATED CODE DO NOT EDIT
//////////////////////////////////
    _validateInfo(fieldName: string): IValidatorInfo {
        switch (fieldName) {
        case 'azureInternalId': {
            return {
                required: false,
                minLength: undefined, maxLength: 255,
                type: 'string',
            };
        }
        case 'baseGroupId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'canSelfRenew': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'boolean',
            };
        }
        case 'companyId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'createdByUserId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'createdDate': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'Date',
            };
        }
        case 'email': {
            return {
                required: true,
                minLength: undefined, maxLength: 255,
                type: 'string',
            };
        }
        case 'hideInUI': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'boolean',
            };
        }
        case 'id': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'impersonationCreatedByUserId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'impersonationModifiedByUserId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'isActive': {
            return {
                required: true,
                minLength: undefined, maxLength: undefined,
                type: 'boolean',
            };
        }
        case 'isDeleted': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'boolean',
            };
        }
        case 'lastReviewed': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'Date',
            };
        }
        case 'modifiedByUserId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'modifiedDate': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'Date',
            };
        }
        case 'name': {
            return {
                required: false,
                minLength: undefined, maxLength: 550,
                type: 'string',
            };
        }
        case 'oktaId': {
            return {
                required: false,
                minLength: undefined, maxLength: 255,
                type: 'string',
            };
        }
        case 'securityAnswer1': {
            return {
                required: false,
                minLength: undefined, maxLength: 255,
                type: 'string',
            };
        }
        case 'securityAnswer2': {
            return {
                required: false,
                minLength: undefined, maxLength: 255,
                type: 'string',
            };
        }
        case 'securityQuestion1Id': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'securityQuestion2Id': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'tODContactInformationId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'tODFacilitatorId': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'string',
            };
        }
        case 'userCreationType': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'number',
            };
        }
        case 'userName': {
            return {
                required: false,
                minLength: undefined, maxLength: 255,
                type: 'string',
            };
        }
        case 'userType': {
            return {
                required: false,
                minLength: undefined, maxLength: undefined,
                type: 'number',
            };
        }
            default: return { required: false, minLength: undefined, maxLength: undefined, type: undefined };
        }
    }

//////////////////////////////////
// AUTO-GENERATED CODE DO NOT EDIT
//////////////////////////////////
    _validate(skip: string[] = []): [number, number, string[]?] {
        let count = 0;
        const errorFields = [];
        if (!skip.includes('azureInternalId') && ValidatorInfo.validateString(this.azureInternalId, this._validateInfo('azureInternalId'))) {
            count++;
            errorFields.push('azureInternalId');
        }
        if (!skip.includes('baseGroupId') && ValidatorInfo.validateString(this.baseGroupId, this._validateInfo('baseGroupId'))) {
            count++;
            errorFields.push('baseGroupId');
        }
        if (!skip.includes('canSelfRenew') && ValidatorInfo.validateString(this.canSelfRenew, this._validateInfo('canSelfRenew'))) {
            count++;
            errorFields.push('canSelfRenew');
        }
        if (!skip.includes('companyId') && ValidatorInfo.validateString(this.companyId, this._validateInfo('companyId'))) {
            count++;
            errorFields.push('companyId');
        }
        if (!skip.includes('createdByUserId') && ValidatorInfo.validateString(this.createdByUserId, this._validateInfo('createdByUserId'))) {
            count++;
            errorFields.push('createdByUserId');
        }
        if (!skip.includes('createdDate') && ValidatorInfo.validateDate(this.createdDate, this._validateInfo('createdDate'))) {
            count++;
            errorFields.push('createdDate');
        }
        if (!skip.includes('email') && ValidatorInfo.validateString(this.email, this._validateInfo('email'))) {
            count++;
            errorFields.push('email');
        }
        if (!skip.includes('hideInUI') && ValidatorInfo.validateString(this.hideInUI, this._validateInfo('hideInUI'))) {
            count++;
            errorFields.push('hideInUI');
        }
        if (!skip.includes('id') && ValidatorInfo.validateString(this.id, this._validateInfo('id'))) {
            count++;
            errorFields.push('id');
        }
        if (!skip.includes('impersonationCreatedByUserId') && ValidatorInfo.validateString(this.impersonationCreatedByUserId, this._validateInfo('impersonationCreatedByUserId'))) {
            count++;
            errorFields.push('impersonationCreatedByUserId');
        }
        if (!skip.includes('impersonationModifiedByUserId') && ValidatorInfo.validateString(this.impersonationModifiedByUserId, this._validateInfo('impersonationModifiedByUserId'))) {
            count++;
            errorFields.push('impersonationModifiedByUserId');
        }
        if (!skip.includes('isActive') && ValidatorInfo.validateString(this.isActive, this._validateInfo('isActive'))) {
            count++;
            errorFields.push('isActive');
        }
        if (!skip.includes('isDeleted') && ValidatorInfo.validateString(this.isDeleted, this._validateInfo('isDeleted'))) {
            count++;
            errorFields.push('isDeleted');
        }
        if (!skip.includes('lastReviewed') && ValidatorInfo.validateDate(this.lastReviewed, this._validateInfo('lastReviewed'))) {
            count++;
            errorFields.push('lastReviewed');
        }
        if (!skip.includes('modifiedByUserId') && ValidatorInfo.validateString(this.modifiedByUserId, this._validateInfo('modifiedByUserId'))) {
            count++;
            errorFields.push('modifiedByUserId');
        }
        if (!skip.includes('modifiedDate') && ValidatorInfo.validateDate(this.modifiedDate, this._validateInfo('modifiedDate'))) {
            count++;
            errorFields.push('modifiedDate');
        }
        if (!skip.includes('name') && ValidatorInfo.validateString(this.name, this._validateInfo('name'))) {
            count++;
            errorFields.push('name');
        }
        if (!skip.includes('oktaId') && ValidatorInfo.validateString(this.oktaId, this._validateInfo('oktaId'))) {
            count++;
            errorFields.push('oktaId');
        }
        if (!skip.includes('securityAnswer1') && ValidatorInfo.validateString(this.securityAnswer1, this._validateInfo('securityAnswer1'))) {
            count++;
            errorFields.push('securityAnswer1');
        }
        if (!skip.includes('securityAnswer2') && ValidatorInfo.validateString(this.securityAnswer2, this._validateInfo('securityAnswer2'))) {
            count++;
            errorFields.push('securityAnswer2');
        }
        if (!skip.includes('securityQuestion1Id') && ValidatorInfo.validateString(this.securityQuestion1Id, this._validateInfo('securityQuestion1Id'))) {
            count++;
            errorFields.push('securityQuestion1Id');
        }
        if (!skip.includes('securityQuestion2Id') && ValidatorInfo.validateString(this.securityQuestion2Id, this._validateInfo('securityQuestion2Id'))) {
            count++;
            errorFields.push('securityQuestion2Id');
        }
        if (!skip.includes('tODContactInformationId') && ValidatorInfo.validateString(this.tODContactInformationId, this._validateInfo('tODContactInformationId'))) {
            count++;
            errorFields.push('tODContactInformationId');
        }
        if (!skip.includes('tODFacilitatorId') && ValidatorInfo.validateString(this.tODFacilitatorId, this._validateInfo('tODFacilitatorId'))) {
            count++;
            errorFields.push('tODFacilitatorId');
        }
        if (!skip.includes('userCreationType') && ValidatorInfo.validateNumber(this.userCreationType, this._validateInfo('userCreationType'))) {
            count++;
            errorFields.push('userCreationType');
        }
        if (!skip.includes('userName') && ValidatorInfo.validateString(this.userName, this._validateInfo('userName'))) {
            count++;
            errorFields.push('userName');
        }
        if (!skip.includes('userType') && ValidatorInfo.validateNumber(this.userType, this._validateInfo('userType'))) {
            count++;
            errorFields.push('userType');
        }
        return [count, 8, errorFields];
    }
}
