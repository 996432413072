<div *ngIf="isLoaded && (componentDataSource?.length || searchTerm || filterService?.fullDataList?.length); else showEmptyState">

  <div class="flex-container" [ngClass]="reverseTabsAndFilters ? 'reverse' : ''">
    <!-- /////////////// Tab Component ////////////////// -->
    <div *ngIf="tabcomponent">

      <!-- Nav tabs -->
      <section class="filter-nav">
        <ul class="nav nav-pills" role="tablist">
          <li *ngIf="myDocStatus" class="nav-item" (click)="onTabClick('myDoc')" [value]="'myDoc'">
            <a class="nav-link" [class.active]="tabFilter === 'myDoc'" data-bs-toggle="tab" href="#" role="tab">My Documents</a>
          </li>
          <li *ngIf="tabcomponent.showAllTab" class="nav-item" [value]="'showAllTab'" (click)="onTabClick(tabcomponent.showAllTab)">
            <a class="nav-link" [class.active]="tabFilter === ''" data-bs-toggle="tab" href="#" role="tab">{{tabcomponent.showAllTab}}</a>
          </li>
          <li *ngFor="let t of filteredtabItems" [value]="t" (click)="onTabClick(t)">
            <a class="nav-link" [class.active]="tabFilter === t.displayText" data-bs-toggle="tab" href="#" role="tab">{{ t.displayText }}</a>
          </li>
        </ul>
      </section>

      <!-- DropDown -->
      <section class="filter-mobile">
        <div class="large-filter-mobile">
          <select class="mb10" id="tab_selector" [(ngModel)]="tabFilter" (change)="onTabItemSelectChange($event.target.value)">
            <option *ngIf="myDocStatus" [value]="'myDoc'">My Documents</option>
            <option *ngIf="tabcomponent.showAllTab" [value]="''">{{ tabcomponent.showAllTab }}</option>
            <option *ngFor="let item of dropdownItems(filteredtabItems)" [value]="item">{{item}}</option>
          </select>
        </div>
      </section>
    </div>
    <!-- ///////////////////////////////////////// -->

    <div>
      <!-- /////////////// Filters ////////////////// -->
      <!-- Standard Filter -->
      <ng-container *ngIf="!isAdvancedFilterEnabled && defaultSearch !== '' ">
        <search-input [searchTerm]="searchTerm" placeholder="Filter by {{defaultSearch}}" (filterSearch)="filter($event)"></search-input>
        <div *ngIf="!reverseTabsAndFilters" class="row">
          <div class="col-md-12">
            <div class="results"> {{resultsCount()}} </div>
          </div>
        </div>
      </ng-container>

      <!-- Advanced Filter -->
      <ng-container *ngIf="isAdvancedFilterEnabled">
        <jjk-datagrid-advanced-filter [searchTerm]="searchTerm" [searchFields]="searchFields" [placeholder]="defaultSearch ? 'Filter by ' + defaultSearch : ''" [tableData]="componentDataSource" [prePopulatedFilters]="prePopulatedFilters" (applyFilters)="applyAdvancedFilters($event)" [filtersApplied]="filtersApplied" [showResultCount]="!reverseTabsAndFilters" [searchHint]="searchHint">
          <ng-container *ngFor="let filter of advancedfilterColumns; let i = index">
            <jjk-datagrid-advanced-filter-item [hidden]="filter.hidden" [columnName]="filter.columnName" [displayText]="filter.displayText" [filterType]="filter.filterType" [groupBy]="filter.groupBy"></jjk-datagrid-advanced-filter-item>
          </ng-container>
        </jjk-datagrid-advanced-filter>
      </ng-container>
      <!-- ///////////////////////////////////////// -->
    </div>
  </div>

  <div *ngIf="reverseTabsAndFilters" class="row">
    <div class="col-md-12">
      <div class="results"> {{resultsCount()}} </div>
    </div>
  </div>

  <!-- Minimap -->
  <sms-minimap [data]="componentDataSource" [columnOptions]="columnConfigs" [savedColumns]="savedColumns" (updateColumns)="minimapSaveColumnSelection($event)" (onSelectionUpdated)="minimapSelectionUpdated($event)"></sms-minimap>

  <!-- DataGrid -->
  <div *ngIf="!prePopulatedFilters || filtersApplied" class="table-responsive" [class.tab-pane]="isTabPane">
    <table class="table table-striped custom-table" [data]="componentDataSource" sms-sortable-table [defaultSort]="defaultSort" [defaultSortDirection]="defaultSortDirection">

      <!-- Header -->
      <thead>
        <tr>
          <ng-container *ngFor="let col of columnConfigs; let i = index">
            <ng-container *ngIf="!col.hidden">
              <ng-container *ngIf="col.canSort; then sortableHeader; else standardHeader"></ng-container>
              <ng-template #sortableHeader>
                <th [sms-sort-direction]="col.columnName === 'date' ? 'desc' : null" sms-sortable-column="{{col.columnName}}" [popoverContent]="col.popoverContent" [popoverTitle]="col.popoverTitle">
                  {{col.displayText}}
                </th>
              </ng-template>
              <ng-template #standardHeader>
                <th [ngClass]="col.columnType === 'Checkbox' && col.showSelectAll ? 'selectAllColumn' : ''">
                  <ng-container *ngIf="col.columnType === 'Checkbox' && col.showSelectAll; then selectAllColumn; else regularColumn"></ng-container>
                  <ng-template #regularColumn>
                      <span class="htmlHeader text-wrap" [innerHTML]="col.displayText | trustedContent"></span>
                      <ng-container *ngIf="col.popoverContent">
                      <jjk-popover additional [content]="col.popoverContent" [title]="col.popoverTitle">&nbsp;</jjk-popover>
                      </ng-container>
                  </ng-template>
                  <ng-template #selectAllColumn>
                      <label class="form-label" for="selectAll">{{col.displayText}}</label>
                      <input type="checkbox" name="selectAll" [(ngModel)]="selectAllValue" (change)="onAllRowsSelected(col)" (click)="disableSelectAll(col, event)">
                  </ng-template>
                </th>
              </ng-template>
            </ng-container>
          </ng-container>
        </tr>
      </thead>

      <!-- Rows -->
      <tbody>
        <tr *ngFor="let row of componentDataSource | slice:0:[itemsToShow]; let iRow = index;">
          <ng-container *ngFor="let col of columnConfigs, let i = index">
            <td *ngIf="!col.hidden" sms-hideable-column-data="{{col.columnName}}" class="wrapwhitespace">
              <!-- Text Column -->
              <span *ngIf="col.columnType == 'Text' ">
                <span [innerHTML]="row | gridGetField:col"></span>
              </span>

              <!-- Input Column -->
              <span *ngIf="col.columnType === 'Input'">
                <input [(ngModel)]="row[col.columnName]" maxLength="{{col.maxLength}}" />
              </span>

              <!-- Custom Column -->
              <span *ngIf="col.columnType === 'Custom' ">
              <div #TitleWrapper>
                <!-- Custom Column Title-->
                <div class="small" *ngIf="col.title">{{row | gridGetRowValue:col.title}}</div>

                <!-- Custom Column Content-->
                <ng-container *ngIf="col.routerLink !== null && col.routerLink !== undefined; then contentWithlink; else contentWithoutlink"></ng-container>
                <ng-template #contentWithlink>
                  <a auth [authFeature]="viewAuthFeature" [authEntity]="row" (click)='onChildComponentClick(row)' [routerLink]="row | buildRouterLink:col" class="row-title">
                    <span class="title" [innerHTML]="row | gridGetField:col"></span>
                  </a>
                  <ng-container *ngIf="viewAuthFeature">
                    <span auth [authFeature]="viewAuthFeature" [authEntity]="row" [authNegate]="true" class="title" [innerHTML]="row | gridGetField:col"></span>
                  </ng-container>
                </ng-template>

                <ng-template #contentWithoutlink>
                  <span *ngIf="!col.contentAsButton" class="title" [innerHTML]="row | gridGetField:col"></span>
                  <!-- Custom Column Content Component -->
                  <jjk-datagrid-custom-column-content *ngIf="col.contentAsButton" [component]="col" [row]="row" [value]="row | gridGetField:col" (action)="onChildComponentEvent($event)" [authFeature]="viewAuthFeature">
                  </jjk-datagrid-custom-column-content>
                </ng-template>
              </div>

                <ul class="actions">
                  <!-- Column Action Components -->
                  <ng-container *ngFor="let component of row.columnLinks; let iLink = index; let isFirst = first">

                    <ng-container *ngIf="iLink < visibleActions; then normalAction; else additionalActions">
                    </ng-container>

                    <ng-template #normalAction>
                      <div class="actionsWrapper">
                      <jjk-datagrid-column-custom-content *ngIf="row && component" [component]="component" [cell]="row" (action)='onChildComponentActionEmit($event)' [ngClass]="{'first-action-item': isFirst}" auth [authFeature]="component.authFeature" [authEntity]="row">
                      </jjk-datagrid-column-custom-content>
                      </div>
                    </ng-template>

                    <!-- Aditional Actions shown in a drop-down menu -->
                    <ng-template #additionalActions>
                      <div class="actionsWrapper">
                      <span id="ellipsis">
                        <ng-container *ngIf="iLink == visibleActions">
                          <a role="button" id="moreActions" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true" data-bs-target="ellipsis" auth [authFeature]="component.authFeature" [authEntity]="row">
                            <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
                          </a>

                          <div class="dropdown-menu" aria-labelledby="moreActions">
                            <ng-container *ngFor="let component of row.columnLinks; let sublink = index">
                              <div [ngClass]="{'dropdown-item': sublink >= visibleActions && row && component}" (click)="completeClick(component['componentType'], component['routerLink'], sublink, iRow)">
                                <jjk-datagrid-column-custom-content #divMenu id="divMenu" *ngIf="sublink >= visibleActions && row && component" [component]="component" [cell]="row" [rowIdx]="iRow" index="{{sublink}}" (action)='onChildComponentActionEmit($event)' [ngClass]="{'ellipsis-action-item':true}">
                                </jjk-datagrid-column-custom-content>
                              </div>
                            </ng-container>
                          </div>

                        </ng-container>
                      </span>
                      </div>
                    </ng-template>
                  </ng-container>
                </ul>

                <!-- Custom Column CustomLoader -->
                <div *ngIf="col.customLoader && getRowValue(row, col.customLoader)">
                  <jjk-loader [marginLeft]="'10px'" [loadingText]="row | gridGetRowValue:col.customLoader"></jjk-loader>
                </div>

                <!-- Custom Column Description -->
                <div *ngIf="col.summary">
                  <span [innerHTML]="row | gridGetRowValue:col.summary | truncate:col.summaryLength"></span>
                  <a *ngIf="col.routerLink" (click)='onChildComponentClick(row)' [routerLink]="row | buildRouterLink:col"><strong>Read More...</strong></a>
                </div>

                <!-- Custom Column footer-->
                <ng-container *ngIf="col.footerRouterLink !== null && col.footerRouterLink !== undefined; then footerWithlink; else footerWithoutlink"></ng-container>
                <ng-template #footerWithlink>
                  <div class="CustomColumnFooter small" *ngIf="col.footerValue && getRowValue(row, col.footerValue)">
                    <a (click)='onChildComponentClick(row)' [routerLink]="row | buildRouterLink:col:true">
                      <span [innerHTML]="col.footerDisplayText"></span>
                      <span [innerHTML]="row | gridGetRowValue:col.footerValue"></span>
                    </a>
                  </div>
                </ng-template>
                <ng-template #footerWithoutlink>
                  <div class="CustomColumnFooter small" *ngIf="col.footerValue && getRowValue(row, col.footerValue)">
                    <span [innerHTML]="col.footerDisplayText"></span>
                    <span [innerHTML]="row | gridGetRowValue:col.footerValue"></span>
                  </div>
                </ng-template>

                <div *ngIf="col.fileTypeFooter">
                  <a *ngIf="row[col.fileType]" target="_blank" title="" download (click)='onChildComponentClick(row)' [ngClass]="!col.downloadable || (col.downloadable && row[col.downloadable]) ? 'loading' : 'noDownload'" auth [authFeature]="col.authFeature" [authEntity]="row">
                    <jjk-datagrid-column-file-download [row]="row" [col]="col"></jjk-datagrid-column-file-download>
                  </a>
                  <jjk-loader *ngIf="col.downloading && row[col.downloading] && col.downloadable && row[col.downloadable]" [marginLeft]="'10px'" [loadingText]="''"></jjk-loader>
                  <ng-container *ngIf="col.authFeature">
                    <jjk-datagrid-column-file-download auth [authFeature]="col.authFeature" [authNegate]="true" [authEntity]="row" [row]="row" [col]="col" [showDownload]="false">
                    </jjk-datagrid-column-file-download>
                  </ng-container>
                </div>
              </span>

              <!-- File Column -->
              <span *ngIf="col.columnType === 'File' ">

                <!-- fileType is always required regardless of the download method whether api downloads or external fileType is safe for visibility -->
                <a *ngIf="row[col.fileType]" title="" download [attr.href]="row[col.fileUrl]" (click)="download(row, col)" [ngClass]="!col.downloadable || (col.downloadable && row[col.downloadable]) ? 'loading' : 'noDownload'" auth [authFeature]="col.authFeature" [authEntity]="row">
                  <jjk-datagrid-column-file-download [row]="row" [col]="col"></jjk-datagrid-column-file-download>
                </a>
                <span class="position-absolute">
                  <jjk-loader *ngIf="col.downloading && row[col.downloading] && col.downloadable && row[col.downloadable]" [loadingText]="''"></jjk-loader>
                </span>

                <ng-container *ngIf="col.authFeature">
                  <jjk-datagrid-column-file-download auth [authFeature]="col.authFeature" [authNegate]="true" [authEntity]="row" [row]="row" [col]="col" [showDownload]="false">
                  </jjk-datagrid-column-file-download>
                </ng-container>
              </span>

              <!-- Label Column -->
              <span *ngIf="col.columnType === 'Label' ">

                <ng-container [ngSwitch]="applyLabelColor(row, col.color)">
                  <span *ngSwitchCase="'blue'" class="badge bg-primary" [innerHTML]="row | gridGetField:col"></span>
                  <span *ngSwitchCase="'gray'" class="badge bg-secondary" [innerHTML]="row | gridGetField:col"></span>
                  <span *ngSwitchCase="'green'" class="badge bg-success" [innerHTML]="row | gridGetField:col"></span>
                  <span *ngSwitchCase="'red'" class="badge bg-danger" [innerHTML]="row | gridGetField:col"></span>
                  <span *ngSwitchCase="'yellow'" class="badge bg-warning text-dark" [innerHTML]="row | gridGetField:col"></span>
                  <span *ngSwitchCase="'light'" class="badge bg-info" [innerHTML]="row | gridGetField:col"></span>
                  <span *ngSwitchCase="'white'" class="badge bg-light text-dark" [innerHTML]="row | gridGetField:col"></span>
                  <span *ngSwitchCase="'black'" class="badge bg-dark" [innerHTML]="row | gridGetField:col"></span>
                </ng-container>
              </span>

              <!-- Toggle Column -->
              <span *ngIf="col.columnType === 'Toggle' ">
                <jjk-datagrid-column-toggle [component]="col" [row]="row" [columnName]="col.columnName" [disableWhen]="col.disableWhen" [authFeature]="col.authFeature" (action)='onChildComponentEvent($event)'></jjk-datagrid-column-toggle>
              </span>

              <!-- Checkbox Column -->
              <span *ngIf="col.columnType === 'Checkbox'" [ngClass]="col.showSelectAll ? 'selectAllColumn': ''">
                <jjk-datagrid-column-checkbox [component]="col" [row]="row" [columnName]="col.columnName" (action)="onRowSelected($event)" [disableSelection]="col.disableSelection" (selectionDisabledAction)="col.disableSelection" (click)="disableSelection(col, row, event)"></jjk-datagrid-column-checkbox>
              </span>
            </td>
          </ng-container>
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="(!prePopulatedFilters || filtersApplied) && itemsToShow < componentDataSource?.length && displayShowMore" class="more col-md-12 text-center">
    <button type="button" class="btn btn-link" (click)="showMore()">Show More</button>
  </div>
</div>

<ng-template #showEmptyState>
  <div *ngIf="!isEmptyGridEnabled && componentDataSource?.length === 0; else showEmptyGrid">
    <jjk-empty-state auth [authFeature]="createAuthFeature" (action)='onEmptyStateAddNewClick($event)' [showAddButton]="showAddButtonEmptyState" [displayText]="emptyStateMessage"></jjk-empty-state>
  </div>
</ng-template>

<ng-template #showEmptyGrid>
  <div *ngIf="isEmptyGridEnabled && componentDataSource?.length === 0">

    <div class="row">
      <div class="col-md-12">
        <div class="results"> {{resultsCount()}} </div>
      </div>
    </div>

    <br /><br />

    <div class="table-responsive">
      <table class="table table-striped custom-table">
        <thead>
          <tr>
            <ng-container *ngFor="let col of columnConfigs">
              <th>
                {{col.displayText}}
                <ng-container *ngIf="col.popoverContent">
                  <jjk-popover additional [content]="col.popoverContent" [title]="col.popoverTitle">&nbsp;</jjk-popover>
                </ng-container>
              </th>
            </ng-container>
          </tr>
        </thead>
      </table>
    </div>
  </div>
</ng-template>