import { AfterViewInit, Component, ContentChildren, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, SimpleChanges } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { DataGridAdvancedFilterItemComponent } from '@components/datagrid-advanced-filter-item/datagrid-advanced-filter-item.component';
import { DataGridAdvancedFilterComponent } from '@components/datagrid-advanced-filter/datagrid-advanced-filter.component';
import { DataGridAdvancedSearchComponent } from '@components/datagrid-advanced-search/datagrid-advanced-search.component';
import { DataGridColumnCheckboxComponent } from '@components/datagrid-column-checkbox/datagrid-column-checkbox.component';
import { DataGridColumnFileComponent } from '@components/datagrid-column-file/datagrid-column-file.component';
import { DataGridColumnComponent } from '@components/datagrid-column/datagrid-column.component';
import { SmsColumnDefinition } from '@components/hacksaw/common/models/sms-column-definition.model';
import { SmsColumnToggleEvent } from '@components/hacksaw/common/models/sms-column-toggle-event.model';
import { SmsMinimapService, smsMinimapServiceServiceProvider } from '@components/hacksaw/sms-minimap/sms-minimap.service';
import { smsSortServiceServiceProvider } from '@components/hacksaw/sms-sortable-column/sms-sort.service';
import { TabItemComponent } from '@components/tab-item/tab-item.component';
import { TabsComponent } from '@components/tabs/tabs.component';
import { LoggedInUserInfo } from '@env/LoggedInUserInfo';
import { isNotNullOrUndefined } from '@microsoft/applicationinsights-core-js';
import { Filter } from '@models/advanced-filter/filter.model';
import { FilterService } from '@services/filter.service';
import { getPropertyIfExists, isNullOrUndefined, isValidArray } from '@utilities/helpers';

@Component({
    selector: 'jjk-datagrid',
    templateUrl: './datagrid.component.html',
    styleUrls: ['./datagrid.component.scss'],
    providers: [TabsComponent, DataGridAdvancedFilterComponent, smsMinimapServiceServiceProvider, smsSortServiceServiceProvider],
})
export class DataGridComponent implements OnInit, OnDestroy, AfterViewInit {

    constructor(
        private tab: TabsComponent,
        public filterService: FilterService,
        private smsMinimapService: SmsMinimapService,
    ) { }

    private _tableData: any[];
    @Input()
    set tableData(tableData: any[]) {
        const checkboxColumn = this.childColumnComponentList ? this.childColumnComponentList.first as DataGridColumnCheckboxComponent : undefined;
        if (checkboxColumn && checkboxColumn.showSelectAll) {
            this._tableData.filter((r) => !tableData.includes(r)).forEach((r) => r[checkboxColumn.columnName] = false);
        }
        this._tableData = tableData;
        this.onTableDataChanges();
        this.updateSelectAllColumn();
    }
    get tableData() { return this._tableData; }

    // Allows empty grid to be shown, otherwise empty state component is shown
    @Input() isEmptyGridEnabled = false;

    @Input() itemsToShow = 50;
    @Input() itemsPerPage = 50;
    @Input() defaultSearch = '';
    @Input() defaultSort = '';
    @Input() defaultSortDirection = 'asc';
    @Input() visibleActions = 3;
    @Input() searchTerm = '';
    @Input() isTabPane = true;
    @Input() createAuthFeature = '';
    @Input() viewAuthFeature = '';
    @Input() displayShowMore = true;
    @Input() showAddButtonEmptyState = true;
    @Input() emptyStateMessage = 'No Records Exist - Add New';
    @Input() prePopulatedFilters: Observable<Filter[]>;
    @Input() reverseTabsAndFilters = false;
    @Input() searchHint: string;
    @Input() myDocStatus = false;

    @Output() action = new EventEmitter();
    @Output() ChildComponentClick = new EventEmitter();
    @Output() selectedtabFilter = new EventEmitter();
    @Output() onColumnsVisibilityUpdated = new EventEmitter<SmsColumnToggleEvent>();
    @Output() onSelectAllUpdated = new EventEmitter<boolean>();

    @ContentChildren(DataGridColumnComponent) childColumnComponentList !: QueryList<DataGridColumnComponent>;
    @ContentChildren(TabsComponent) childrenTabComponentList !: QueryList<TabsComponent>;
    @ContentChildren(DataGridAdvancedSearchComponent) childrenDataGridFilterComponentList !: QueryList<DataGridAdvancedSearchComponent>;

    columnConfigs: any[];
    componentsLoaded = false;
    isLoaded = false;
    itemShowDefault: number = this.itemsToShow;
    filteredMultiTab = false;
    statusChangeAlreadyFiltered = false;
    filtersApplied = false;

    private _selectAllValue = false;
    public get selectAllValue() {
        return this._selectAllValue;
    }
    public set selectAllValue(value) {
        if (this._selectAllValue !== value) {
            this.onSelectAllUpdated.emit(value);
        }
        this._selectAllValue = value;
    }

    searchFields: string[];

    public tabFilter = this.filterService.tabFilter ? this.filterService.tabFilter : '';
    public tabcomponent: TabsComponent;
    private tabItems: TabItemComponent[];
    public filteredtabItems: TabItemComponent[] = [];

    private isAdvancedFilterEnabled = false;
    private datagridAdvancedSearch: DataGridAdvancedSearchComponent;
    private advancedfilterColumns: DataGridAdvancedFilterItemComponent[];
    private isFirstChange = true;

    savedColumns: SmsColumnDefinition[];
    private savedColumns$: Subscription;
    previousTableData: any[];

    componentDataSource: any[];
    
    ngOnInit() {
        this.savedColumns$ = this.filterService.savedColumns$
            .subscribe(() => {
                this.savedColumns = this.filterService.savedColumns;
            });

        if (!isNullOrUndefined(this.filterService.itemsToShow)) { this.itemsToShow = this.filterService.itemsToShow; }

        if (LoggedInUserInfo.Instance.clientUserInfo.isNonAuth) {
            this.viewAuthFeature = '';
            this.createAuthFeature = '';
            this.emptyStateMessage = 'There are no Safety Data Sheets available to you. Please contact your Safety Management Suite administrator with any questions.';
        }

        // allow tooltips to work with dynamic content
        (<any>$('jjk-datagrid')).tooltip({
            selector: '[data-bs-toggle="tooltip"]'
        });
    }

    ngAfterViewInit() {
        this.columnConfigs = this.childColumnComponentList.toArray();

        // If no priority has been set, we've to calculated it
        if (!this.columnConfigs.some((x) => !!x.priority)) {
            for (let i = 0; i < this.columnConfigs.length; i++) {
                this.columnConfigs[i].priority = i + 1;
            }
        }

        // Check if the Advanced Search Component exists as a child in the grid
        const AdvancedFilterComponents = this.childrenDataGridFilterComponentList.toArray();
        if (AdvancedFilterComponents.length > 0) {
            this.isAdvancedFilterEnabled = true;
            this.datagridAdvancedSearch = AdvancedFilterComponents[0];
            this.advancedfilterColumns = this.datagridAdvancedSearch.childrenFilterColumnComponentList.toArray();

        }

        // Check if the Tab Component exists as a child in the grid
        const tabcomponents = this.childrenTabComponentList.filter((t) => isValidArray(t.tabItems) || t.columnName !== undefined);
        if (tabcomponents.length) {
            this.tabcomponent = tabcomponents[0];
        }

        // Workaround to avoid ExpressionChangedAfterItHasBeenCheckedError due to the complex behavior
        // in this component and its elements. This code gives the time to angular to display the template
        // components without beign affected for changes happening in the middle of that execution
        setTimeout(() => {
            this.columnConfigs = this.childColumnComponentList.toArray();

            // If no priority has been set, we've to calculated it
            if (!this.columnConfigs.some((x) => !!x.priority)) {
                for (let i = 0; i < this.columnConfigs.length; i++) {
                    this.columnConfigs[i].priority = i + 1;
                }
            }
            this.componentsLoaded = true;
            this.onTableDataChanges();
        }, 100);
    }

    onTableDataChanges() {
        if (!this.componentsLoaded) { return; }

        if (isNullOrUndefined(this.tableData)) { return; }

        // Tab Component
        if (this.tabcomponent) {

            if (this.tabFilter === '' || !this.isAdvancedFilterEnabled) { this.filteredtabItems = []; }

            if (this.tabcomponent.tabItems) {
                this.tabItems = this.tabcomponent.tabItems;

                // Check for tabItems with the "showEmpty" property, which are displayed even when there are no records
                this.tabItems.map((item) => {
                    if (item.showEmpty) {
                        this.filteredtabItems = this.tab.addTabItem(this.filteredtabItems, item);
                    }
                });
            }

            // Checks if the Advanced Filter are Enabled to Validate and create all the tab Items that will be displayed
            const originalTabFilter = this.tabFilter;
            this.tabFilter = '';

            if (this.isAdvancedFilterEnabled && this.tableData.length > 0) {

                // This applies when the column name is provided
                if (!isNullOrUndefined(this.tabcomponent.columnName)) {
                    const colName = this.tabcomponent.columnName;

                    let reducedDataSource: any[] = [];
                    reducedDataSource = this.filterService.fullDataList.map((prop) => prop[colName]);
                    // Remove Duplicates
                    reducedDataSource = [...new Set(reducedDataSource)];

                    if (this.tabItems.length > 0) {
                        const _tabItems = this.tabItems.filter((t) => reducedDataSource.includes(t.displayText));
                        this.filteredtabItems = this.tab.sortFilteredTabItems(_tabItems);
                    } else {

                        const _tabItems: TabItemComponent[] = [];
                        reducedDataSource.map((t) => {
                            if (!isNullOrUndefined(t) && t !== '') {
                                const tabItem = new TabItemComponent();
                                tabItem.displayText = t;
                                _tabItems.push(tabItem);
                            }
                        });
                        this.filteredtabItems = this.tab.sortFilteredTabItems(_tabItems);
                    }
                } else {
                    this.tableData.map((data) => {
                        this.filteredtabItems = this.tab.tabItemValidate(this.tabItems, this.filteredtabItems, data, this.tabcomponent.columnName);
                    });
                }

            } else {
                this.filterService.fullDataList.map((data) => {
                    this.filteredtabItems = this.tab.tabItemValidate(this.tabItems, this.filteredtabItems, data, this.tabcomponent.columnName);
                });
            }
            // .........................................................

            // Verifies if the current tab was removed to select the first one
            this.tabFilter = originalTabFilter;
            const currentTab = this.filteredtabItems.find((item) => item.displayText === this.tabFilter);

            // Verifies if the 'showAllTab' property was specified, otherwise the first available tab must be selected.
            if ((!this.tabcomponent.showAllTab && this.tabFilter === '')) {
                // Gets the available TabItems.
                const filtered = this.tab.sortFilteredTabItems(this.filteredtabItems);
                if (filtered && filtered.length > 0) {
                    this.tabFilter = filtered[0].displayText;
                    this.filterDataSource(filtered[0]);
                }
            }

            // If there were changes and the current tab has multiple search fields validate if the data source needs to be filtered
            if (this.filteredtabItems.length > 0) {
                const currentTabMultiple = this.filteredtabItems.find((item) => item.displayText === this.tabFilter && !!item.searchFields);
                if (!this.tabcomponent.showAllTab && currentTabMultiple && !this.filteredMultiTab) {
                    this.filteredMultiTab = true;
                    this.filterDataSource(currentTabMultiple);
                }
            }

            // Check if the currentTab is undefined because to the last record of a tab was deleted due to a change in status
            setTimeout(() => {
                if (!isNullOrUndefined(this.filterService)) {
                    // In case there were a status change, the results should be filtered again
                    if (!isNullOrUndefined(currentTab) && currentTab.displayText !== '' && this.filterService.fullDataList.length === this.filterService.filteredDataList.length
                        && this.filteredtabItems.length > 0 && !this.statusChangeAlreadyFiltered) {
                        this.filterDataSource(currentTab);
                        this.statusChangeAlreadyFiltered = true;
                        return;
                    }

                    if (!this.tabcomponent.showAllTab && isNullOrUndefined(currentTab)) {
                        const firstTabAvailable = this.selectFirstTabAvailable();

                        if (isNullOrUndefined(firstTabAvailable)) {
                            this.onTabItemSelectChange('');
                        } else {
                            if (firstTabAvailable && firstTabAvailable.searchFields && firstTabAvailable.searchFields.length > 0) {
                                this.filterDataSource(firstTabAvailable);
                            } else {
                                this.onTabItemSelectChange(firstTabAvailable.displayText);
                            }
                        }
                    }

                    if (this.filterService.stillInFeature && this.filterService.tabFilter !== '' && (this.tabFilter !== this.filterService.tabFilter) && (this.filterService.filteredDataList === this.tableData)) {
                        this.filterService.stillInFeature = false;
                        this.onTabItemSelectChange(this.filterService.tabFilter);
                        return;
                    }

                    this.statusChangeAlreadyFiltered = false;
                }
            }, 100);
        }

        if (this.filterService.searchFields) { this.searchFields = this.filterService.searchFields; }

        // Checks if a default TabItem was selected
        if (this.tabFilter === '' && this.filterService.tabFilter !== '') { this.tabFilter = this.filterService.tabFilter; }

        this.tableData.forEach((element) => {
            const columnLinks = this.columnConfigs[0].componentArray;

            if (isValidArray(columnLinks)) {
                element.columnLinks = this.shouldDisplayAction(element, columnLinks);
            }
        });
        if (!this.tabcomponent || (this.tabcomponent && (this.tabcomponent.showAllTab || this.tabcomponent.tabItems.length > 0 ||
            (this.filterService.tabFilter && (this.tableData.length !== this.filterService.fullDataList.length || this.filterService.filteredDataList.length === this.filterService.fullDataList.length)) ||
            (!this.filterService.tabFilter && this.filterService.fullDataList.length === 0)))) {
            this.componentDataSource = this.tableData;
            this.isLoaded = true;
        }
    }

    filterDataSource(filtered: TabItemComponent) {
        if (filtered.searchFields && filtered.searchFields.length > 0) {

            this.filterService.getTabbedListArray(filtered.searchFields);

        } else {
            this.filterService.getTabbedList(filtered.displayText);
            this.componentDataSource = this.filterService.filteredDataList;
        }

        if (this.isAdvancedFilterEnabled) {
            this.filterService.performAdvancedFilter(this.filterService.filters);
        }
    }

    onTabClick(item: any) {
        if (item === 'myDoc'){
            this.onTabItemSelectChange('myDoc')
            this.selectedtabFilter.emit('myDoc');
        } else {
        this.tabFilter = item.displayText ? item.displayText : '';
        this.tabFilter === '' ? this.onTabItemSelectChange('') : this.filterDataSource(item);
        this.selectedtabFilter.emit(item.displayText);
    }
    }

    ngOnChanges(changes: SimpleChanges){
        if (changes['myDocStatus'] && changes['myDocStatus'].currentValue && this.isFirstChange){
            this.isFirstChange = false;
            this.tabFilter = 'myDoc';
            this.onTabItemSelectChange('myDoc');
            this.selectedtabFilter.emit('myDoc');
        }
    }

    onTabItemSelectChange(item: any) {
        const currentTab = this.filteredtabItems.find((i) => i.displayText === item);
        if (!isNullOrUndefined(currentTab) && !isNullOrUndefined(currentTab.searchFields)) {
            this.filterService.getTabbedListArray(currentTab.searchFields);
        } else {
            this.tabFilter = item;
            this.filterService.getTabbedList(item);
        }

        if (this.isAdvancedFilterEnabled) {
            this.filterService.performAdvancedFilter(this.filterService.filters);
        }
        this.selectedtabFilter.emit(item);

        }

    selectFirstTabAvailable() {
        if (this.tabcomponent) {
            if (!this.tabcomponent.showAllTab && this.tabFilter) {
                // Gets the available TabItems.
                const filtered = this.tab.sortFilteredTabItems(this.filteredtabItems);
                this.tabFilter = filtered && filtered.length > 0 ? filtered[0].displayText : '';
                return filtered[0];
            } else {
                this.tabFilter = '';
            }
        }
    }

    ngOnDestroy(): void {
        if (this.savedColumns$) { this.savedColumns$.unsubscribe(); }
    }

    completeClick(componentType: string, routerLink: string, index: number, rowIdx: number){
        if(componentType === 'link'){
            const idButton: string = (routerLink !== null ) ? 'LinkOption' : 'ButtonOption';
            document.getElementById(`${idButton}${String(index)}-${rowIdx}`).click();
        }
    }

    onChildComponentActionEmit(childcomponent: any) {
        childcomponent.component.action.emit(childcomponent.cell);
    }

    onChildComponentEvent(childcomponent: any) {
        childcomponent.component.action.emit(childcomponent);
    }

    onChildComponentClick(row) {
        this.ChildComponentClick.emit(row);
    }

    onEmptyStateAddNewClick(event: any) {
        this.action.emit(event);
    }

    filter(event) {
        if (!isNullOrUndefined(this.tabcomponent)) {
            const tabIndex = this.filteredtabItems.findIndex((index) => index.displayText === this.tabFilter);
            const currentTab: TabItemComponent = this.filteredtabItems[tabIndex];

            if (isNullOrUndefined(currentTab)) {
                this.onTabItemSelectChange('');
            } else {
                if (currentTab && currentTab.searchFields && currentTab.searchFields.length > 0) {
                    this.filterDataSource(currentTab);
                } else {
                    this.onTabItemSelectChange(currentTab.displayText);
                }
            }
        }

        this.filterService.searchTerm = event;
    }

    public removeFromGrid(id: string, idColumnName = 'id') {
        const indexToRemove = this.tableData.findIndex((i) => i[idColumnName] === id);

        if (indexToRemove > -1) {
            // Validate if it is the last record
            if (this.tableData.length === 1) {
                this.tableData.splice(indexToRemove, 1);

                if (!isNullOrUndefined(this.tabcomponent)) {

                    if (!this.isAdvancedFilterEnabled) {
                        this.tabFilter = '';
                        this.onTableDataChanges();
                    } else {
                        // Since its the last element is necessary to remove the tabItem
                        const tabIndex = this.filteredtabItems.findIndex((index) => index.displayText === this.tabFilter);
                        this.filteredtabItems.splice(tabIndex, 1);
                    }

                    const firstTabAvailable = this.selectFirstTabAvailable();

                    if (isNullOrUndefined(firstTabAvailable)) {
                        this.onTabItemSelectChange('');
                    } else {
                        if (firstTabAvailable && firstTabAvailable.searchFields && firstTabAvailable.searchFields.length > 0) {
                            this.filterDataSource(firstTabAvailable);
                        } else {
                            this.onTabItemSelectChange(firstTabAvailable.displayText);
                        }
                    }
                }

            } else {
                this.tableData.splice(indexToRemove, 1);

                if (!isNullOrUndefined(this.tabcomponent) && this.isAdvancedFilterEnabled) {
                    this.onTabItemSelectChange(this.tabFilter);
                }
            }
        }

        this.componentDataSource = this.tableData;
    }

    getRowValue(row: any, property: string) {
        return getPropertyIfExists(row, property);
    }

    applyLabelColor(row: any, columnName: string) {
        if (row && columnName) {
            if (row[columnName]) {
                return row[columnName];
            } else {
                return columnName;
            }
        }
    }

    download(row: any, colConfig: DataGridColumnFileComponent) {
        if (colConfig.action.observers.length && (colConfig.downloadable === undefined || row[colConfig.downloadable])) {
            colConfig.action.emit(row);
        }
    }

    resultsCount() {
        return !isNullOrUndefined(this.tableData)
            ? this.tableData.length === 1 ? this.tableData.length + ' Result' : this.tableData.length + ' Results' : '';
    }

    shouldDisplayAction(row: any, componentArray: any[]) {
        const shouldDisplayComponent = componentArray.filter((s) => {
            let show = true;
            if (s.authFeature) {
                show = false;
                const split = s.authFeature.toString().split(',');
                const domain = split[0];
                const type = (split.length > 1) ? split[1] : '';
                const ignoreGroup = (split.length > 2) ? split[2] === 'ignoreGroup' : false;
                show = LoggedInUserInfo.Instance.userInfo.checkAuthFeature(domain, type, row, false, ignoreGroup);
            }
            if (isNullOrUndefined(s.shouldDisplay)) {
                if (show) {
                    return s;
                }
            } else {
                if (typeof s.shouldDisplay === 'boolean') {
                    if (s.shouldDisplay && show) {
                        return s;
                    }
                }

                const displayConfig = s.shouldDisplay.split(':');
                const column = displayConfig[0];
                const flag = displayConfig.length > 1 ? displayConfig[1] : '';

                const value = this.getRowValue(row, column).toString();

                if (flag !== '') {
                    if (flag.indexOf(',') >= 0) {
                        const displayconditions = flag.split(',');

                        if (displayconditions.find((x) => x === value)) {
                            if (show) {
                                return s;
                            }
                        }
                    } else {
                        if (value === flag && show) {
                            return s;
                        }
                    }
                }
            }
        });

        return shouldDisplayComponent;
    }

    applyAdvancedFilters(searchFilters) {
        searchFilters.find((f) => {
            if (f.column === this.defaultSearch) { this.searchTerm = f.filterValue; }
        });

        this.filterService.performAdvancedFilter(searchFilters);
        setTimeout(() => {
            this.filtersApplied = true;
        });
    }

    minimapSaveColumnSelection($event) {
        this.filterService.savedColumns = $event;
    }

    minimapSelectionUpdated(eventData: SmsColumnToggleEvent) {
        this.onColumnsVisibilityUpdated.emit(eventData);
    }

    minimapReset() {
        const columns = SmsColumnDefinition.buildColumnInfo(this.componentDataSource, this.columnConfigs);

        if (!isNullOrUndefined(columns) && columns.length > 1) {
            this.smsMinimapService.initColumnInfo(columns);
            this.filterService.savedColumns = columns;
        }
    }

    minimapColumnToggle(columnName: string, priority = 0) {
        if (isNullOrUndefined(this.savedColumns) || this.savedColumns.length === 0) {
            const defaultcolumns = SmsColumnDefinition.buildColumnInfo(this.componentDataSource, this.columnConfigs);
            this.savedColumns = defaultcolumns;
            this.filterService.savedColumns = defaultcolumns;
        }

        const columnToToggle = this.savedColumns.find((column) => column.value === columnName);
        if (columnToToggle) {
            if (priority > 0) {
                columnToToggle.visibility.hidden = false;
                columnToToggle.visibility.manuallyToggled = !columnToToggle.visibility.manuallyToggled;
            } else {
                columnToToggle.visibility.hidden = true;
                columnToToggle.visibility.manuallyToggled = true;
            }

            this.smsMinimapService.setColumnInfo(columnToToggle);
        }

        this.filterService.savedColumns = this.savedColumns;

    }

    showMore() {
        if (!isNullOrUndefined(this.filterService.itemsToShow)) {
            this.filterService.itemsToShow = this.filterService.itemsToShow + this.itemsPerPage;
            this.itemsToShow = this.filterService.itemsToShow;
        } else {
            this.itemsToShow = this.itemsToShow + this.itemsPerPage;
        }
    }

    showLess() {
        this.itemsToShow = this.itemShowDefault;
    }

    onRowSelected(event: any) {
        this.updateSelectAllColumn();
        event.component.action.emit(event);
    }

    onAllRowsSelected(checkboxComponent: any) {
        const visibleItems = this.itemsToShow > this.tableData.length ? this.tableData.length : this.itemsToShow;
        for (let i = 0; i < visibleItems; i++) {
            this.tableData[i][checkboxComponent.columnName] = this.selectAllValue;
        }
        checkboxComponent.selectAllAction.emit(this.selectAllValue);
    }

    updateSelectAllColumn() {
        const checkboxColumn = this.childColumnComponentList ? this.childColumnComponentList.first as DataGridColumnCheckboxComponent : undefined;
        if (checkboxColumn && checkboxColumn.showSelectAll) {
            const visibleItems = this.itemsToShow > this.tableData.length ? this.tableData.length : this.itemsToShow;
            const rows = this.tableData.filter((r, i) => r[checkboxColumn.columnName] === true && i < visibleItems);
            this.selectAllValue = rows.length === visibleItems && visibleItems > 0;
        }
    }

    disableSelectAll(checkboxComponent: any, event: any) {
        if (checkboxComponent.disableSelectAll) {
            if (isNotNullOrUndefined(event))
                event.preventDefault();
            checkboxComponent.selectionDisabledAction.emit();
        }
    }

    disableSelection(component: any, row: any, event: any) {
        if (component.disableSelection && !row[component.columnName]) {
            if (isNotNullOrUndefined(event))
                event.preventDefault();
            component.selectionDisabledAction.emit();
        }
    }

    dropdownItems(items){
        return items.map((item) => item.displayText);
    }
}
